import { isEmpty } from 'ramda';

const parseCookie = function (cookieString) {
	if (!cookieString ) {
		return false;
	}
	return cookieString
		.split(';')
		.map(v => v.split('='))
		.reduce((acc, v) => {
			try {
				acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
			} catch (e) {
				return acc;
			}
			return acc;
		}, {});
};

const getPropFromCookie = (parsedCookie, propName) => {
	if (!parsedCookie || isEmpty(parsedCookie || !parsedCookie[propName])) {
		return null;
	}

	const data = parsedCookie[propName] || {};

	if (isEmpty(data)) {
		return null;
	}

	let parsedJSON = null;

	try {
		parsedJSON = JSON.parse(data);
	} catch (e) {
		console.warn('error parsing cookie', e);
	}

	return parsedJSON;
};

const deleteCookie = function (cookieName, config) {
	const { baseURL } = config;
	document.cookie = `${cookieName}=;expires=${new Date(0).toUTCString()}path=[/];domain=${baseURL}`;
};

const cookieUtils = {
	parseCookie,
	getPropFromCookie,
	deleteCookie,
};

export default cookieUtils;
