import React from 'react';
import Loader from '../../../components/loader/Loader';


export default function PaymentProcessing() {
  return <div className='payment'>
    <Loader
        message="Processing payment"
        unwrapped={false}
        inline={false}
        children={[]}
    />
  </div>
}
