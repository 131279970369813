import React from 'react';
import classnames from 'classnames';

const TickIcon = ({ className, fill = "#1e1e50" }) => (
    <svg className={ classnames('otrl-icon__shadow', className) } xmlns="http://www.w3.org/2000/svg" id="svg835" version="1.1" viewBox="0 0 74.64 74.64" width="120" height="120" >
        <circle cx="35.91" cy="35.88" r="22.12" style={ { isolation: 'isolate' } } fill={fill}/>
        <path d="M32.337 41.753l12.021-12.02a1 1 0 011.414 0 .91.91 0 010 1.287L34.41 42.383a1 1 0 01-1.414 0l-.644-.644z" style={ { isolation: 'isolate' } } fill="#fff"/>
        <path d="M32.768 42.248l-4.582-4.582a1 1 0 010-1.414.74.74 0 011.047 0l4.766 4.766z" style={ { isolation: 'isolate' } } fill="#fff"/>
    </svg>
);



export default TickIcon;
