import { DOCUMENT_LENGTH } from "../constants";

const validateDOBwithCheckDigit = (val, { day, month, year }) => {
	const num = val.toUpperCase();
	const validationError = 'Unfortunately, there was an error with the document number you’ve provided; please check your date of birth and document details are correct.';

	let parsedNumber = `${year.slice(2)}${month.padStart(2, '0')}${day.padStart(2, '0')}`;
	
	const checkDigit = parsedNumber.split('').reduce((acc, n, i) => {
		let weight;
		
		switch (i % 3) {
			case 0:
				weight = 7;
				break;
			case 1:
				weight = 3;
				break;
			case 2: 
				weight = 1;
				break;
			default:
				weight = 0;
		}

		return acc + +n * weight;
	}, 0) % 10;

	parsedNumber += checkDigit;
	
	if (num !== parsedNumber) {
		return validationError;
	}

	return null;
}

const validateId = (id, dateOfBirth) => {
	if (!id) {
		return 'Required';
	}

	if (id.length !== DOCUMENT_LENGTH.id) {
		return 'Identity card number should be 18 characters';
	}
	// in case of id we should validate only first 7 digits(DOB in YYMMDD format + check digit)
	return validateDOBwithCheckDigit(id.slice(0, 7), dateOfBirth);
}

const validatePassport = (passport, dateOfBirth) => {
	if (!passport) {
		return 'Required';
	}

	if (passport.length !== DOCUMENT_LENGTH.passport) {
		return 'Passport number should be 20 characters';
	}

	// in case of passport we should validate only last 7 digits
	return validateDOBwithCheckDigit(passport.slice(13), dateOfBirth);
}

const validateLicenseNumber = (val = '', customerInfo) => {
  if (!val) {
    return 'Required';
  }

  if (val.length !== DOCUMENT_LENGTH.license) {
    return 'Driving licence number should be 16 characters';
  }

  const { lastName = '', dateOfBirth } = customerInfo;
  const { day, month, year } = dateOfBirth;

  const surname = lastName.toUpperCase();
  const num = val.toUpperCase();

  const validationError = 'Unfortunately, there was an error with the document number you’ve provided; please check your date of birth and document details are correct.';

  let parsedNumber = '';

  // transform lastName to be 5 letters long
  if (surname.startsWith('MAC')) {
    parsedNumber += 'MC' + surname.slice(3, 6);
  } else {
    parsedNumber += surname.slice(0, 5);
  }
  parsedNumber = parsedNumber.padEnd(5, '9');
  parsedNumber += year[2]; // adding decade digit

  if (!num.startsWith(parsedNumber)) {
    // validating first 6 letters
    return validationError;
  }

  
  parsedNumber = month.at(-1);
  parsedNumber += day.padStart(2, '0');
  parsedNumber += year[3];

  if (num.slice(7, 11) !== parsedNumber) {
    // validating [8-11] letters
    return validationError;
  }

  return null;
}

const documentValidationUtils = {
	validateDOBwithCheckDigit,
	validateId,
	validatePassport,
	validateLicenseNumber,
};

export default documentValidationUtils;
