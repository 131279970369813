import React, { useEffect, useState } from 'react';
import { identity } from 'ramda';
import { Formik, Field, ErrorMessage } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import moment from 'moment'
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import BasketState from "../../../../interfaces/BasketState";
import { selectConfig } from '../../../../features/config/configSlice';
import ConfigState from "../../../../interfaces/ConfigState";
import RailcardsState from '../../../../interfaces/RailcardsState';
import UserState from '../../../../interfaces/UserState';
import { selectBasket } from '../../../../features/basket/basketSlice';
import { selectRailcards } from '../../../../features/railcards/railcardsSlice';
import { selectUser } from '../../../../features/user/userSlice';
import { APP_PAGES, RAILCARD_AGE_ERRORS, RAILCARD_CODES, NAME_MAX_LENGTH, TRACKING_EVENTS, TRACKING_STEPS } from '../../../../constants';
import ConditionalRender from "../../../../components/conditionalRender";
import Loader from "../../../../components/loader/Loader"
import Railcard from "../../../railcards/railcard/Railcard";
import ageValidationUtils from "../../../../utils/ageValidationUtils";
import stringUtils from "../../../../utils/stringUtils";
import './BasicInfo.scss';

import { trackEvent, getRailcardEventPayload } from '../../../../utils/googleTagManager';

const BasicInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { brand } = useSelector<ConfigState, ConfigState>(selectConfig);
  const { customerInfo, selectedRailcard, chosenPlan, isRenewing } = useSelector<BasketState, BasketState>(selectBasket);
  const { railcards } = useSelector<RailcardsState, RailcardsState>(selectRailcards);
  const { firstNames: loggedInUserName, surname: loggedInUserSurname, title: loggedInUserTitle } = useSelector<UserState, UserState>(selectUser);

  const secondCardHolder = selectedRailcard?.secondCardHolder;

  let secondHolderFirstName, secondHolderTitle, secondHolderLastName = null;

  let { firstName, lastName, title } = customerInfo;

  const { dateOfBirth } = customerInfo;
  
  if (secondCardHolder) {
    secondHolderFirstName = customerInfo.secondHolderFirstName;
    secondHolderTitle = customerInfo.secondHolderTitle;
    secondHolderLastName = customerInfo.secondHolderLastName;
  }

  firstName = firstName || loggedInUserName;
  lastName = lastName || loggedInUserSurname;
  title = title || loggedInUserTitle;
  
  const dobRequired = selectedRailcard?.dobRequired;
  const secondCardHolderRequired = selectedRailcard?.secondCardHolderRequired;

  const isBackButtonAllowed = !isRenewing;

  const validateAge = ageValidationUtils.validateAgeAndRailcardCombination(selectedRailcard, chosenPlan);
  
  const RequiredSchema = msg => Yup.string().required(msg);
  const BasicInfoSchema = Yup.object().shape(
    {
      title: Yup.string().required('Please enter your title'),
      firstName: Yup.string().min(2, 'First name must be 2 or more characters long').required('Please enter your first name'),
      lastName: Yup.string().min(2, 'Last name must be 2 or more characters long').required('Please enter your last name'),
      ...(dobRequired && { 
        dateOfBirth: Yup.object({
          day: Yup.string(),
          month: Yup.string(),
          year: Yup.string()
        }).test('validDOB', 'Please enter a valid date', function (dob) {
          return validateAge(this, dob);
        }) 
      }),
      ...(secondCardHolder && {
        secondHolderTitle: Yup
          .string()
          .min(2, 'Title must be 2 or more characters long')
          .when(['secondHolderFirstName', 'secondHolderLastName'], {
            is: identity,
            then: (schema) => schema.required('Please enter your secondary card holder title'),
            otherwise: (schema) => schema.concat(secondCardHolderRequired ? RequiredSchema('Please enter your secondary card holder title') : null)
          }),
        secondHolderLastName: Yup
          .string()
          .min(2, 'Last name must be 2 or more characters long')
          .when(['secondHolderTitle', 'secondHolderFirstName'], {
            is: identity,
            then: (schema) => schema.required('Please enter your secondary card holder first name'),
            otherwise: (schema) => schema.concat(secondCardHolderRequired ? RequiredSchema('Please enter your secondary card holder first name') : null)
          }),
        secondHolderFirstName: Yup
          .string()
          .min(2, 'First name must be 2 or more characters long')
          .when(['secondHolderTitle', 'secondHolderLastName'], {
            is: identity,
            then: (schema) => schema.required('Please enter your secondary card holder last name'),
            otherwise: (schema) => schema.concat(secondCardHolderRequired ? RequiredSchema('Please enter your secondary card holder last name') : null)
          })
      })
    }, 
    [
      ['secondHolderTitle', 'secondHolderFirstName'],
      ['secondHolderTitle', 'secondHolderLastName'],
      ['secondHolderFirstName', 'secondHolderLastName'],
    ] // prop to allow circular dependencies in schema (not well documented)
  );

  const initialValues = {
    title,
    firstName: stringUtils.capitalizeFirstLetter(firstName), 
    lastName: stringUtils.capitalizeFirstLetter(lastName), 
    dateOfBirth,
    secondHolderTitle,
    secondHolderLastName: stringUtils.capitalizeFirstLetter(secondHolderFirstName),
    secondHolderFirstName: stringUtils.capitalizeFirstLetter(secondHolderLastName)
  };

  const currentYear = new Date().getFullYear();
  const yearsList = Array.from({ length: 100 }, (v, i) => currentYear - i);
  const monthsList = moment.monthsShort();

  
  const updateCustomerInfo = (customerInfo) => {
    dispatch({ type: 'basket/updateCustomerInfo', payload: {
      customerInfo
    } })
  };

  const removeCustomerInfo = () => {
    dispatch({ type: 'basket/removeCustomerInfo' })
  };
  
  const goNext = () => {
    navigate(dobRequired && !isRenewing ? APP_PAGES.PROOF_OF_AGE : APP_PAGES.RAILCARD_PHOTO);
  };

  const goBack = () => {
    removeCustomerInfo();
    navigate(APP_PAGES.HOMEPAGE);
  };

  useEffect(() => {
    if (!selectedRailcard) {
      navigate(APP_PAGES.HOMEPAGE);
    }

    if (!secondCardHolder) {
      dispatch({ type: 'basket/removeSecondaryCustomerInfo' });
    }
  }, [navigate, selectedRailcard]);


  const selectOneYearYNGRailcard = (setErrors) => {
    const railcard = railcards.find(railcard => railcard.railcardCode === RAILCARD_CODES['16-25']);

    if (railcard) {
      setIsLoading(true);

      dispatch({
        type: 'basket/selectRailcard',
        payload: {
          railcard,
          chosenPlan: 1
        }
      });

      const payload = getRailcardEventPayload({ railcard, chosenPlan });
      
      trackEvent({
        brand,
        eventName: TRACKING_EVENTS.ADDED_TO_BASKET,
        payload,
        step: TRACKING_STEPS.ORDER_STARTED,
      });

      // Clear the age and railcard combination validation errors
      setErrors({});

      setTimeout(function () {
        setIsLoading(false);
      }, 3000);
    }
  };

  return (
      <Formik
        initialValues={initialValues}
        
        validationSchema={BasicInfoSchema}

        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
          updateCustomerInfo(values);
          goNext();
        }}
      >
        {({
          errors,
          setErrors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          submitForm,
          isSubmitting,
        }) => {
          const transformNameInput = (e) => {
            e.target.value = (e.target.value || '').trimStart();
            e.target.value = stringUtils.capitalizeFirstLetter(e.target.value);
            handleChange(e);
          }
          
          return isLoading ? 
            <Loader
                message="Updating railcard selection"
                unwrapped={false}
                inline={false}
                children={[]}
            /> : (
            <div className='user-info'>
              <Row className='user-info__content'>
                <Col>
                  <Form onSubmit={handleSubmit} className='user-info__form'>
                    <h1>About you</h1>
                    <p>We need some details about you</p>

                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="title">Title *</Form.Label>
                      <Form.Select 
                        aria-label="Primary railcard holder title"
                        aria-describedby="title-error-message"
                        disabled={isRenewing}
                        name="title"
                        placeholder="Title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      >
                        <option value="">Title</option>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Miss">Miss</option>
                        <option value="Ms">Ms</option>
                        <option value="Mx">Mx</option>
                        <option value="Dr">Dr</option>
                      </Form.Select>
                      <ErrorMessage name="title" render={msg => <div role="alert" id="title-error-message" className="form-text">{msg}</div>} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="firstName">First name *</Form.Label>
                      <Form.Control 
                        aria-describedby="firstName-error-message"
                        name="firstName"
                        type="text"
                        placeholder="First name"
                        onChange={transformNameInput}
                        onBlur={handleBlur}
                        value={values.firstName}
                        maxLength={NAME_MAX_LENGTH}
                      />
                      <ErrorMessage name="firstName" render={msg => <div role="alert" id="firstName-error-message" className="form-text">{msg}</div>} />
                    </Form.Group>
          
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="lastName">Last Name *</Form.Label>
                      <Form.Control 
                        aria-describedby="lastName-error-message"
                        name="lastName"
                        type="text"
                        placeholder="Last name"
                        onChange={transformNameInput}
                        onBlur={handleBlur}
                        value={values.lastName}
                        maxLength={NAME_MAX_LENGTH}
                      />
                      <ErrorMessage name="lastName" render={msg => <div role="alert" id="lastName-error-message" className="form-text">{msg}</div>} />
                    </Form.Group>


                    <ConditionalRender condition={secondCardHolder}>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="secondHolderTitle">Secondary card holder title*</Form.Label>
                        <Form.Select 
                          aria-describedby="secondHolderTitle-error-message"
                          aria-label="Secondary card holder"
                          disabled={isRenewing}
                          name="secondHolderTitle"
                          placeholder="Title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.secondHolderTitle}
                        >
                          <option value="">Title</option>
                          <option value="Mr">Mr</option>
                          <option value="Mrs">Mrs</option>
                          <option value="Miss">Miss</option>
                          <option value="Ms">Ms</option>
                          <option value="Mx">Mx</option>
                          <option value="Dr">Dr</option>
                        </Form.Select>
                        <ErrorMessage name="secondHolderTitle" render={msg => <div role="alert" id="secondHolderTitle-error-message" className="form-text">{msg}</div>} />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="secondHolderFirstName">Secondary card holder first name {secondCardHolderRequired && '*'}</Form.Label>
                        <Form.Control 
                          aria-describedby="secondHolderFirstName-error-message"
                          name="secondHolderFirstName"
                          type="text"
                          placeholder="First name"
                          onChange={transformNameInput}
                          onBlur={handleBlur}
                          value={values.secondHolderFirstName}
                          maxLength={NAME_MAX_LENGTH}
                        />
                        <ErrorMessage name="secondHolderFirstName" render={msg => <div role="alert" id="secondHolderFirstName-error-message" className="form-text">{msg}</div>} />
                      </Form.Group>
            
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="secondHolderLastName">Secondary card holder last name {secondCardHolderRequired && '*'}</Form.Label>
                        <Form.Control 
                          aria-describedby="secondHolderLastName-error-message"
                          name="secondHolderLastName"
                          type="text"
                          placeholder="Last name"
                          onChange={transformNameInput}
                          onBlur={handleBlur}
                          value={values.secondHolderLastName}
                          maxLength={NAME_MAX_LENGTH}
                        />
                        <ErrorMessage name="secondHolderLastName" render={msg => <div role="alert" id="secondHolderLastName-error-message" className="form-text">{msg}</div>} />
                      </Form.Group>
                    </ConditionalRender>

                    <ConditionalRender condition={dobRequired}>
                      <Form.Group>
                        <Form.Label>Date of birth *</Form.Label>
                        <div className='dob-selector'>
                          <Field aria-describedby="dob-error-message" as='select' disabled={isRenewing} name='dateOfBirth.day' className='form-select'>
                            <option value="" disabled selected hidden>DD</option>
                            {[...Array(31)].map((el, i) => <option key={i} value={(i + 1).toString().padStart(2, '0')}>{i + 1}</option>)}
                          </Field>
                          <Field aria-describedby="dob-error-message" as='select' disabled={isRenewing} name='dateOfBirth.month' className='form-select'>
                            <option value="" disabled selected hidden>MM</option>
                            {monthsList.map((el, i) => <option key={i} value={(i + 1).toString().padStart(2, '0')}>{el}</option>)}
                          </Field>
                          <Field aria-describedby="dob-error-message" as='select' disabled={isRenewing} name='dateOfBirth.year' className='form-select'>
                            <option value="" disabled selected hidden>YY</option>
                            {yearsList.map((el, i) => <option key={i} value={el}>{el}</option>)}
                          </Field>
                        </div>
                        <ErrorMessage name="dateOfBirth" render={msg => <div role="alert" id="dob-error-message" className="form-text">{msg}</div>} />
                        {errors.dateOfBirth === RAILCARD_AGE_ERRORS.YNG_3_YEAR_MAX_AGE && (<div className="mt-3">
                          <p>Click here to <Button aria-label="Change your selected railcard to the one year validity option" onClick={() => selectOneYearYNGRailcard(setErrors)} variant="primary">Change to 1 year</Button></p>
                        </div>)}
                      </Form.Group>
                    </ConditionalRender>
                  </Form>
                </Col>

                <Col className="d-none d-md-block" md='auto'>
                  {selectedRailcard && <Railcard railcard={selectedRailcard} chosenPlan={chosenPlan}/>}
                </Col>
              </Row>

              <footer className='user-info__footer'>
                {isBackButtonAllowed ? <Button aria-label="Go back to the home screen" variant="outline-primary" onClick={goBack}>Back</Button> : <span>&nbsp;</span>}
                <Button aria-label="Go to the next screen" variant="primary" disabled={isSubmitting} onClick={submitForm}>Next</Button>
              </footer>
              
            </div>
        
        )}}
      </Formik>
    );
};

export default BasicInfo;
