import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  errors: [],
  success: false,
};

export const passwordReset = createSlice({
  name: 'passwordReset',
  initialState,
  reducers: {
    resetPassword: (state) => {
      state.errors = [];
      state.success = false;
    },
    resetPasswordSuccess: (state) => {
      state.errors = [];
      state.success = true;
    },
    resetPasswordFailure: (state, action) => {
      state.errors = action.errors;
      state.success = false;
    },
    resetForm: (state) => {
      state.errors = [];
      state.success = false;
    },
  },
});

export const { setErrors } = passwordReset.actions;

export const selectPasswordReset = (state) => state.passwordReset;

export default passwordReset.reducer;
