import * as React from "react";
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Yup from 'yup';
import TickIcon from '../../../../components/icons/TickIcon';
import IDiscountCodeState from "../../../../interfaces/DiscountCodeState";
import { selectDiscount } from '../../../discount/discountSlice';
import './DiscountCode.scss';


interface DiscountCodeFormValues {
  discountCode: string;
}

const DiscountCodeSchema = Yup.object().shape({
  discountCode: Yup.string().required(),
});


const DiscountCodeForm = () => {
  const dispatch = useDispatch();

  const initialValues: DiscountCodeFormValues = { 
    discountCode: '',
  };

  const handleSubmit = ({ discountCode }) => {
    dispatch({ type: 'discount/applyDiscount', payload: {
      discountCode
    }});
  }

  const { error: discountCodeError, success } = useSelector<IDiscountCodeState, IDiscountCodeState>(selectDiscount);

  React.useEffect(() => {
    dispatch({ type: 'discount/reset' });
  }, [dispatch]);

  return (<div className="discount-form">
    <Formik
      initialValues={initialValues}

      validationSchema={DiscountCodeSchema}

      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
        }, 400);
        handleSubmit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        const isDisabled = isSubmitting || !values.discountCode;

        console.log('discount code success', success);

        return (
          <Form className="discount-form" onSubmit={handleSubmit}>
            <React.Fragment>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="discountCode">
                    <Form.Label>Discount code</Form.Label>
                    <Form.Control 
                      name="discountCode"
                      type="discountCode"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.discountCode}
                    />
                    <Form.Text className="text-muted">
                      {errors.discountCode && touched.discountCode && errors.discountCode}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Button type="submit" disabled={isDisabled} className="discount-form__apply" variant="primary">
                    Apply
                  </Button>
                </Col>
              </Row>
              {success && (<Row>
                <Col>
                  Discount code valid!
                  <TickIcon />
                </Col>
              </Row>)}
            </React.Fragment>
            {discountCodeError && (<Alert className="error-well" variant="danger">
              <p>{discountCodeError}</p>
            </Alert>)}
          </Form>
        )
      }}
    </Formik>
  </div>);
};

export default DiscountCodeForm;
