import * as React from "react";
import './NotFound.scss';

export default function NotFound() {
  return <div className='page-not-found'>
    <h1>
        404
    </h1>
    <div>
        This page could not be found
    </div>
  </div>
}
