import { call, put, select, takeLatest } from 'redux-saga/effects'
import customerService from '../services/customer';
import { selectConfig } from '../features/config/configSlice';
import { AUTH_ERRORS, AUTH_FRIENDLY_ERRORS } from '../constants';


export function* login({ values }) {
  const config = yield select(selectConfig);

  const { email, password } = values;

  let result = null;
  let authErrors = [];

  try {
    result = yield call(customerService.auth, {
      email, password, config
    });
    
    const { accessToken, accessTokenExpires, errors, refreshToken, user } = result;
    

    authErrors = errors;

    if (result.errors) {
      const message = result.errors[0].message;

      if (message === AUTH_ERRORS.CUSTOMER_LOCKED_OUT) {
        authErrors[0] = { message: AUTH_FRIENDLY_ERRORS.CUSTOMER_LOCKED_OUT };
      }

      if (message === AUTH_ERRORS.CUSTOMER_AUTH_FAILED) {
        authErrors[0] = { message: AUTH_FRIENDLY_ERRORS.CUSTOMER_AUTH_FAILED };
      }

      throw new Error(authErrors[0].message);
    }

    yield put({type: 'login/loginSuccess'});
    yield put({type: 'user/setUser', payload: user});
    yield put({type: 'user/setTokens', payload: {
      accessToken,
      accessTokenExpires,
      refreshToken
    }});
  } catch (e) {
    yield put({type: 'login/loginFailure', payload: authErrors });
  }
}

function* loginSaga() {
  yield takeLatest('login/userLogin', login);
}

export default loginSaga;
