import React, { useEffect } from 'react';
import { useDispatch} from 'react-redux';
import Select from '../../../../components/select/Select';
import userInterfaceUtils from '../../../../utils/userInterfaceUtils';
import './RailcardsFilter.scss';


const filterOptions = {
    age: {
        label: 'How old are you?',
        options: [
            { name: '16-17', value: '16-17' },
            { name: '18-25', value: '18-25' },
            { name: '26-30', value: '26-30' },
            { name: '31-59', value: '31-59' },
            { name: '60+', value: '60' }
        ]
    },
    travelWith: {
        label: 'Who do you usually travel with?',
        options: [
            { name: 'Alone', value: 'alone' },
            { name: 'With a friend', value: 'partner' },
            { name: 'As a family', value: 'children' }
        ]
        
    },
    travelSE: {
        label: 'Do you travel in London and the South East?',
        options: [
            { name: 'Yes', value: 'yes' },
            { name: 'No', value: 'no' }
        ]
    }
};


export default function RailcardsFilter() {
    const dispatch = useDispatch();

    const renderFilters = () => {
        return Object.keys(filterOptions).map((el) => {
            return <Select 
                key={el}
                {...filterOptions[el]}
                onChange={(value) => dispatch({ type: 'railcards/filterRailcards', data: { name: el, value } })}
            />
        });
    };

    const goToRailcards = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        const el = document.getElementById("railcard-options");
        el.scrollIntoView({block: "start", behavior: "smooth"});
    }

    useEffect(() => {
        userInterfaceUtils.deepLinkAndSmoothScrollToPageElement('choose-railcard');
    }, []);
    
    return (
        <section id="choose-railcard" className='railcard-filters'>
            <h1>Choose your Railcard</h1>
            <p>Not sure which Railcard is right for you? Use our handy Railcard Calculator below. Or you can <a onClick={goToRailcards} href="#railcard-options">skip this step</a> if you know which one you need.</p>
            <div className='railcard-filters__selectors'>
                {renderFilters()}
            </div>
        </section>
    );
}