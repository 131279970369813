import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: '',
  accessTokenExpires: '',
  email: '',
  firstNames: '',
  id: '',
  loggedIn: false,
  refreshToken: '',
  surname: '',
  title: '',
};


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const {
        email,
        firstNames,
        id,
        surname,
        title,
      } = action.payload;
      
      state.email = email;
      state.firstNames = firstNames;
      state.id = id;
      state.loggedIn = true;
      state.surname = surname;
      state.title = title;
    },
    resetUser: (state) => {
      state.accessToken = '';
      state.accessTokenExpires = '';
      state.email = '';
      state.firstNames = '';
      state.id = '';
      state.loggedIn = false;
      state.refreshToken = '';
      state.surname = '';
    },
    setTokens: (state, action) => {
      const {
        accessToken,
        accessTokenExpires,
        refreshToken
      } = action.payload;

      state.accessToken = accessToken;
      state.accessTokenExpires = accessTokenExpires;
      state.refreshToken = refreshToken;
    }
  },
});

export const { setName } = userSlice.actions;

export const selectUser = (state) => state.user;
export const selectUserAccessToken = (state) => state.user.accessToken;

export default userSlice.reducer;
