const apiUtils = {
	get: async function ({
		url, token, apiAccessToken
	}) {
		const response = await fetch(url, {
			mode: 'cors',
			cache: 'no-cache',
			headers: { 
				'x-access-token': apiAccessToken,
				'Content-Type': 'application/json',
				'x-customer-token': token,
			},
		});
		return response.json(); // parses JSON response into native JavaScript objects
	},

  getImage: async function ({ apiAccessToken, brand, imageUrl, token }) {
	const response = await fetch(imageUrl, {
		mode: 'cors',
		cache: 'no-cache',
		headers: { 
			'x-access-token': apiAccessToken,
			'x-customer-token': token,			
			'x-brand-id': brand,
		},
	});
	
	return response;
  },

  post: async function ({
		url, data = {}, token, apiAccessToken
	}) {
		const response = await fetch(url, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			headers: { 
				'x-access-token': apiAccessToken,
				'Content-Type': 'application/json',
				'x-customer-token': token,
			},
			body: JSON.stringify(data)
		});
		try {
			return response.json(); // parses JSON response into native JavaScript objects
		} catch (e) {
			console.log('POST request did not return valid JSON');
		}
  },

	put: async function ({ apiAccessToken, data, returnResponse, token, url }) {
		const response = await fetch(url, {
			method: 'PUT',
			mode: 'cors',
			cache: 'no-cache',
			headers: { 
				'x-access-token': apiAccessToken,
				'Content-Type': 'application/json',
				'x-customer-token': token,
			},
			body: JSON.stringify(data)
		});
		return returnResponse ? response.json() :true;
  },

	postFormData: async function ({
		apiAccessToken, url, data = {}, token
	}) {
		var formData = new FormData();

		for (const [key, value] of Object.entries(data)) {
			if (value) {
				formData.append(key, value);
			}
		}

		const response = await fetch(url, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			headers: { 
				'x-access-token': apiAccessToken,
				'x-customer-token': token,
			},
			body: formData
		});
		try {
			return response.json(); // parses JSON response into native JavaScript objects
		} catch (e) {
			console.log('POST request did not return valid JSON');
		}
  },

  delete: async function ({
		apiAccessToken, url
	}) {
		await fetch(url, {
			method: 'DELETE',
			mode: 'cors',
			cache: 'no-cache',
			headers: { 
				'x-access-token': apiAccessToken,
				'Content-Type': 'application/json' 
			},
		});
		return true;
  },
};

export default apiUtils;
