
import apiUtils from "../../utils/apiUtils";
import scriptUtils from "../../utils/scriptUtils";


async function braintreeSdk () {
  if (!window.braintree) {
    await scriptUtils.loadScript('https://js.braintreegateway.com/js/braintree-2.32.1.min.js');
  }
}

const paypalService = {
  getClientToken: async function (config) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';

    const path = '/payments/railcards/paypal/token';
    const url = isProd ? `https://api.${baseURL}${path}` : `https://api-${brand}.${baseURL}${path}`;

    const { token } = await apiUtils.post({
      apiAccessToken,
      url
    });
    return token;
  },

  setup (token, onPaymentMethodReceived, options = {}) {
    return braintreeSdk().then(() => new Promise((resolve, reject) => {
        if (!window.braintree) {
          braintreeSdk();
        }
        window.braintree.setup(token, 'paypal', Object.assign({
            headless: true,
            container: 'paypal-container',
            onReady: resolve,
            onError: reject,
            onPaymentMethodReceived
        }, options));
    }));
  },

  setupSingleUse ({
    token, amount, handlePaymentMedthodReceived, config
  }) {

    const { environment } = config;

    const isProd = environment === 'prod';

    const options = {
        singleUse: true,
        intent: 'sale',
        amount: amount / 100,
        currency: 'GBP',
        locale: 'en_gb',
        dataCollector: {
            kount: {
                environment: isProd ? 'production' : 'sandbox'
            },
            paypal: true
        },
        enableShippingAddress: false
    }

    return paypalService.setup(token, handlePaymentMedthodReceived, options);
  },

  checkout: async function({ amount, railcardId, deviceData, nonce, token, config }) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';

    const path = '/payments/railcards/paypal/checkout';
    const url = isProd ? `https://api.${baseURL}${path}` : `https://api-${brand}.${baseURL}${path}`;

    const data = {
      railcardId,
      payment: {
        amount,
        nonce,
        deviceData
      }
    };

    const result = await apiUtils.post({
      apiAccessToken, url, data, token
    });
    return result;
  }
};

export default paypalService;
