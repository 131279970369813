import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { RAILCARD_CODES } from '../../constants';


const initialState = {
  isLoading: false,
  error: null,
  railcards: [],
  filterOptions: {
    age: null,
    travelWith: null,
    travelSE: null
  },
  shownRailcards: [],
  noResultRailcard: null,
  railcardsTimestamp: null,
};

const filterRailcards = (railcards, filters) => {
  let filteredResult = railcards;

  for (let filterName in filters) {
    const filterValue = filters[filterName];

    if (!filterValue) {
      continue;
    }
    
    switch (filterName) {
      case 'age':
        filteredResult = filteredResult.filter(({ minAge, maxAge }) => {
          const [filterMin, filterMax] = filterValue.split('-');

          const isMinMet = minAge ? filterMin >= minAge : true;
          const isMaxMet = maxAge ? filterMax <= maxAge : true;
          
          return isMinMet && isMaxMet;
        });
        break;
      case 'travelWith':
        filteredResult = filteredResult.filter(({ railcardCode }) => {
          if (filterValue === 'children') {
            return railcardCode !== RAILCARD_CODES.TWO_TOGETHER;
          }

          if (filterValue === 'partner') {
            return railcardCode !== RAILCARD_CODES.FAMILY_AND_FRIENDS;
          }

          if (filterValue === 'alone') {
            return ![RAILCARD_CODES.TWO_TOGETHER, RAILCARD_CODES.FAMILY_AND_FRIENDS].includes(railcardCode)
          }

          return true;
        });
        break;
      case 'travelSE':
        filteredResult = filteredResult.filter(({ railcardCode }) => {
          if (filterValue === 'no') {
            return railcardCode !== RAILCARD_CODES.NETWORK;
          }
          return true;
        })
        break;
      default:
        return true;
    }
  }

  return filteredResult;
}

const getDefaultRailcard = (railcards) => {
  return railcards.find(({ railcardCode }) => railcardCode === RAILCARD_CODES.TWO_TOGETHER);
}

export const railcardsSlice = createSlice({
  name: 'railcards',
  initialState,
  reducers: {
    getRailcards: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getRailcardsSuccess: (state, action) => {
      state.isLoading = false;
      state.railcards = action.payload;
      state.shownRailcards = filterRailcards(state.railcards, state.filterOptions);
      state.noResultRailcard = getDefaultRailcard(action.payload);
      state.railcardsTimestamp = moment().format('YYYY-MM-DD');
    },
    getRailcardsFailure: (state, action) => {
      state.error = action.message;
      state.isLoading = false;
      state.railcards = [];
      state.shownRailcards = [];
    },
    filterRailcards: (state, { data }) => {
      state.filterOptions[data.name] = data.value;
      state.shownRailcards = filterRailcards(state.railcards, state.filterOptions)
    },
    resetFilters: (state) => {
      state.filterOptions = initialState.filterOptions;
      state.shownRailcards = filterRailcards(state.railcards, state.filterOptions)
    }
  },
});

export const { getRailcardsSuccess } = railcardsSlice.actions;

export const selectRailcards = (state) => state.railcards;

export default railcardsSlice.reducer;
