import * as React from "react";
import appStore from "../../images/app-store.png";
import playMarket from "../../images/play-market.png";


export default function Footer() {
  return (
    <div className='download-links__wrapper'>
      <div>
          <a href="https://apps.apple.com/gb/app/southeastern-railway/id1544126349"><img alt="App Store icon" src={appStore} /></a>
          <a href="https://play.google.com/store/apps/details?id=southeastern.travelcompanion.prod&pli=1"><img alt="Play Store icon" src={playMarket} /></a>
      </div> 
  </div>
  );
}
