
const capitalizeFirstLetter = (str) => {
    return str ? str[0].toUpperCase() + str.slice(1) : '';  
}

const stringUtils = {
    capitalizeFirstLetter
};

export default stringUtils;
