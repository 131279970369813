import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Pay360SessionState } from '../../../constants';
import Pay360SessionIframe from './Pay360SessionIframe';
import Pay360SessionDetails from './Pay360SessionDetails';
import { Pay360TransactionStates } from '../../../constants';


function Pay360Session({
    src, 
    order, 
    paymentProcessingFailed,
    sessionState,
    transactionState,
}) {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const pay360Errors = {
        "FAILED": 'Sorry, your transaction has been declined by your bank, please contact your bank for more information',
        "CANCELLED": 'Purchase cancelled by customer',
        "INVALID": 'No result returned',
    };

    // handle the session expiring/timing out
    const hasSessionExpired = false;
    const isSessionAlive = !hasSessionExpired && sessionState === Pay360SessionState.READY;

    const paymentFailed = sessionState === Pay360TransactionStates.TERMINATED && pay360Errors[transactionState];

    const paymentCancelled = sessionState === Pay360TransactionStates.TERMINATED && transactionState === "NOT_SUBMITTED";

    const onComplete = () => {
        if (paymentCancelled) {
            dispatch({ type: 'pay360/reset' });
        } else {
            dispatch({ type: 'pay360/paymentProcessing' });
            dispatch({ type: 'pay360/getSession', payload: { navigate }});
        }
    }

    return isSessionAlive ? (
        <Pay360SessionIframe 
            src={src}
            onComplete={onComplete} 
            paymentCancelled={paymentCancelled}
        />
    ) : (
        <Pay360SessionDetails
            state={sessionState}
            transactionState={transactionState}
            order={order}
            hasExpired={hasSessionExpired}
            processingFailed={paymentProcessingFailed}
            paymentFailed={paymentFailed}
        />
    );
}

export default Pay360Session;