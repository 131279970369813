import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  errors: []
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login: (state) => {
      state.errors = [];
    },
    loginFailure: (state, action) => {
      state.errors = action.payload;
    },
    resetForm: (state) => {
      state.errors = [];
    },
  },
});

export const { setErrors } = loginSlice.actions;

export const selectLogin = (state) => state.login;

export default loginSlice.reducer;
