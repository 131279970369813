
const scriptUtils = {
	loadScript (src, id = null) {
        return new Promise(function (resolve, reject) {
            const script = document.createElement('script');
            if (id) {
                script.setAttribute('id', id);
            }

            script.setAttribute('src', src);
            document.head.appendChild(script);

        // All normal amazing browsers
        if (script.onload) {
            script.onload = () => resolve(script);
            script.onerror = () => reject(new Error('error loading script'));
        }

        // Microsofts stupid annoying browsers
        if (script.addEventListener) {
            script.addEventListener('load', () => resolve(script), false);
            script.addEventListener('error', () => reject(new Error('error loading script')), false);
        } else if (script.readyState) {
            script.onreadystatechange = () => resolve(script);
            script.onerror = () => reject(new Error('error loading script'));
        }
    });
}
};

export default scriptUtils;
