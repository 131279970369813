import { call, put, select, takeLatest } from 'redux-saga/effects'
import customerService from '../services/customer';
import cookieUtils from '../utils/cookieUtils';
import { COOKIE_NAME } from '../constants';
import { selectConfig } from '../features/config/configSlice';
import { selectUser } from '../features/user/userSlice';


export function* logout() {
  const config = yield select(selectConfig);
  let user = yield select(selectUser);

  try {
    cookieUtils.deleteCookie(COOKIE_NAME, config);
    yield call(customerService.logout, {
      user, config
    });
    yield put({type: 'basket/resetBasket'});
    yield put({type: 'documents/resetDocuments'});
    yield put({type: 'paypal/reset'});
    yield put({type: 'pendingRailcard/reset'});
    yield put({type: 'user/resetUser'});
    yield put({type: 'login/logoutSuccess'});
  } catch (e) {
    yield put({type: 'login/logoutFailure', payload: e.message });
  }
}

function* logoutSaga() {
  yield takeLatest('login/userLogout', logout);
}

export default logoutSaga;
