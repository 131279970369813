import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import EXIF from "exif-js";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import moment from 'moment';

import Loader from "../../../../components/loader/Loader";

import UserState from "../../../../interfaces/UserState";
import BasketState from "../../../../interfaces/BasketState";
import Railcard from "../../../railcards/railcard/Railcard";
import { selectUser } from '../../../../features/user/userSlice';
import { selectBasket } from '../../../../features/basket/basketSlice';
import { selectConfig } from '../../../../features/config/configSlice';
import { APP_PAGES,  MAX_FILE_UPLOAD_SIZE } from "../../../../constants";
import { FIRST_HOLDER, SECOND_HOLDER, customDropzoneErrorMessages } from '../../../../constants';
import miscUtils from '../../../../utils/miscUtils';
import CheckIcon from "../../../../images/check.png";
import "./RailcardPhoto.scss";

import railcardUtils from '../../../../utils/railcardUtils';
import { useFewDropzone } from './useFewDropzone';
import railcardsService from '../../../../services/railcards';

interface IFile {
  firstHolder?: object,
  secondHolder?: object,
}

const renderLoader = () => {
  return (<Loader
      message="Creating your railcard"
      unwrapped={false}
      inline={false}
      children={[]}
  />);
}

// @ts-ignore no need to worry about this
window.EXIF = EXIF;

export default function RailcardPhoto() {
  const dispatch = useDispatch();

  const handleShowLoginModal = () => {
    dispatch({ type: 'globalModals/openLoginModal' });
  };

  const [isLoading, setIsLoading] = React.useState(false);

  const config = useSelector(selectConfig);
  const user = useSelector<UserState, UserState>(selectUser);

  const [file, setFile] = useState<IFile | null>({
    firstHolder: null,
    secondHolder: null,
  });

  const [firstHolderExistingImage, setFirstHolderExistingImage] = useState(null);
  const [secondHolderExistingImage, setSecondHolderExistingImage] = useState(null);

  const [uploadErrors, setUploadErrors] = useState({});

  const { customerInfo, selectedRailcard, chosenPlan, isRenewing, renewedRailcardId, renewedRailcard } : BasketState = useSelector<BasketState, BasketState>(selectBasket);

  const dobRequired = selectedRailcard?.dobRequired;
  const titleBgColor = selectedRailcard?.titleBgColor;
  const titleFontColor = selectedRailcard?.titleFontColor;
  const railcardName = selectedRailcard?.railcardName;

  const { firstName, lastName, secondHolderFirstName, secondHolderLastName } = customerInfo;

  const navigate = useNavigate();

  const goBack = () => {
    navigate(dobRequired ? APP_PAGES.PROOF_OF_AGE : APP_PAGES.USER_INFO);
  };

  const goNext = () => {
    if (user.loggedIn) {
      setIsLoading(true);

      dispatch({ type: 'railcards/createPendingRailcard', payload: {
        isRenewing,
        renewedRailcardId,
        railcardData,
        navigate
      }});
    } else {
      handleShowLoginModal();
    }
  };

  const styles = {
    background: titleBgColor,
    color: titleFontColor,
  };

  let errorMessage = null;

  const firstHolderDropzone = useFewDropzone({ file, holder: FIRST_HOLDER, setFile, setUploadErrors, uploadErrors });
  const secondHolderDropzone = useFewDropzone({ file, holder: SECOND_HOLDER, setFile, setUploadErrors, uploadErrors });
  const hasSecondHolder = selectedRailcard?.secondCardHolder && secondHolderFirstName && secondHolderLastName;

  const renderPhotoUpload = (holder, existingImage) => {
    const isSecondHolder = holder === SECOND_HOLDER;
    const { getRootProps, getInputProps } = isSecondHolder
      ? secondHolderDropzone
      : firstHolderDropzone;

    const error = uploadErrors[holder];
    
    errorMessage = error
      ? customDropzoneErrorMessages[error.code] || error.message
      : null;

      const existingImageStyles = existingImage
        ? {
            backgroundImage: `url(${existingImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }
        : {};
    
    return (
      <div className="photo-upload">
        <div className="photo-upload__preview" {...getRootProps()} style={existingImageStyles}>
          <input {...getInputProps()} />
          {file[holder] ? (
            <img src={file[holder].preview} alt="" />
          ) : (
            <div className="photo-upload__description">
              {!errorMessage ? (
                <>
                  <h6>
                    Drop your {isSecondHolder ? "2nd cardholder" : ""} photo
                    here or browse
                  </h6>
                  <p>{`Max. file size: ${miscUtils.formatBytes(MAX_FILE_UPLOAD_SIZE)} (JPEG, JPG, PNG)`}</p>
                </>
              ) : (
                errorMessage
              )}
            </div>
          )}
        </div>
        {file[holder] && (
          <span
            className="photo-upload__remove"
            onClick={() => setFile({ ...file, [holder]: null })}
          >
            Remove image
          </span>
        )}
      </div>
    );
  };

  const initialValidUntil = renewedRailcard.validUntil ? moment(renewedRailcard.validUntil, 'YYYY-MM-DD') : moment().subtract(1, 'days');
  // For expired railcards, set a new valid until day from today
  const validUntil = initialValidUntil.isBefore(moment(), 'day') ? moment().subtract(1, 'days') : initialValidUntil;

  const validFrom = moment().format('DD MM YYYY');
  const validTo = railcardUtils.getRailcardValidToDate(validUntil.add(1, 'days'), chosenPlan).format('DD MM YYYY');

  const railcardInfo = {
    railcardCode: selectedRailcard?.railcardCode,
    railcardExpirationLength: `${chosenPlan}Y`,
  };

  const railcardData = { 
    customerId: user.id,
    railcardInfo,
    userInfo: customerInfo,
    primaryPhoto: file['firstHolder'],
    secondaryPhoto: file['secondHolder']
  };

  let canProceed = false;

  // @ts-ignore - define type for uploadErrors
  const hasImageErrors = uploadErrors.firstHolder || uploadErrors.secondHolder;

  if (!hasSecondHolder) {
    canProceed = !!file.firstHolder && !hasImageErrors || isRenewing
  } else {
    canProceed = !!(file.firstHolder && file.secondHolder) && !hasImageErrors || isRenewing;
  }

  useEffect(() => {
    if (!selectedRailcard) {
      navigate(APP_PAGES.HOMEPAGE);
    }
  }, [navigate, selectedRailcard]);

  useEffect(() => {
    async function fetchImages() {
      if (renewedRailcard.primaryPhotoUUID) {
        const response = await railcardsService.getImage({ config, token: user.accessToken, imageUrl: renewedRailcard.primaryPhotoUUID })

        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);
        setFirstHolderExistingImage(objectURL);
      }

      if (renewedRailcard.secondaryPhotoUUID) {
        const response = await railcardsService.getImage({ config, token: user.accessToken, imageUrl: renewedRailcard.secondaryPhotoUUID })

        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);
        setSecondHolderExistingImage(objectURL);
      }
    }

    fetchImages();
  }, [renewedRailcardId])

  return (
    <div className="user-info">
      <Row className="user-info__content">
        {isLoading ? renderLoader() : <React.Fragment>
          <Col>
            <h1>{`Your Digital Railcard photo${hasSecondHolder ? 's' : ''}`}</h1>
            <p>
              {
              hasSecondHolder ? `${firstHolderExistingImage ? 'Please confirm your existing photos or ' : 'Please'} upload passport style photos ${firstHolderExistingImage ? ', if you want to change the existing ones,' : ''} that will be attached to your Railcard.` :
                `${firstHolderExistingImage ? 'Please confirm your existing photo or ' : 'Please'} upload a passport style photo${firstHolderExistingImage ? ', if you want to change the existing one,' : ''} that will be attached to your Railcard.`
              }
            </p>

            <div
              className={`railcard-preview ${
                hasSecondHolder && "two-card-holders"
              }`}
              style={styles}
            >
              <h5>{railcardName}</h5>
              <div className="railcard-preview__photo-upload">
                {renderPhotoUpload(FIRST_HOLDER, firstHolderExistingImage)}
                {hasSecondHolder && renderPhotoUpload(SECOND_HOLDER, secondHolderExistingImage)}
              </div>

              <footer className="railcard-preview__footer">
                <Row className="issued-to">
                  <Col>
                    <p>Issued to</p>
                    <h4>
                      {firstName} {lastName}
                    </h4>
                  </Col>
                  {hasSecondHolder && (
                    <Col>
                      <p>2nd cardholder</p>
                      <h4>
                        {secondHolderFirstName} {secondHolderLastName}
                      </h4>
                    </Col>
                  )}
                </Row>
                <Row className="valid">
                  <Col>
                    <p>Valid from</p>
                    <h4>{validFrom}</h4>
                  </Col>
                  <Col>
                    <p>Valid until</p>
                    <h4>{validTo}</h4>
                  </Col>
                </Row>
              </footer>
            </div>

            {(// @ts-ignore do this later
              uploadErrors.firstHolder || uploadErrors.secondHolder) && <Alert role="alert" className="mb-5" variant="danger"><p>Sorry, we can&apos;t use this image, please upload a portrait image</p></Alert>
            }
          </Col>

          <Col md="auto">
            {selectedRailcard && (
              <div className="d-none d-md-block">
                <Railcard railcard={selectedRailcard} chosenPlan={chosenPlan} />
              </div>
            )}

            <div className="photo-description">
              <p>Please make sure your image:</p>
              <ul>
                  <li>
                      <img src={CheckIcon} alt="" /> Is instantly recognisable as you
                  </li>
                  <li>
                      <img src={CheckIcon} alt="" /> Is a Passport style photograph
                  </li>
                  <li>
                      <img src={CheckIcon} alt="" /> There is nothing covering your face
                  </li>
                  <li>
                      <img src={CheckIcon} alt="" /> Do not use an image filter
                  </li>
                  <li>
                      <img src={CheckIcon} alt=""/> Please note, if a member of rail staff can't confirm you are the Railcard holder, you may be charged the full ticket price or a penalty fare
                  </li>
              </ul>
              
            </div>
          </Col>
        </React.Fragment>}
      </Row>

      <footer className="user-info__footer">
        {!isRenewing && <Button aria-label="Go back to the 'About you' screen" variant="outline-primary" onClick={goBack}>
          Back
        </Button>}
        <Button aria-label={`Go to the next screen${!canProceed ? ': disabled as you have not uploaded valid photo(s) for your railcard' : ''}`} disabled={!canProceed} variant="primary" onClick={goNext}>Next</Button>
      </footer>
    </div>
  );
}