import * as React from "react";
import { useState } from 'react';
import { toLower } from 'ramda';
import DOMPurify from 'dompurify'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from "react-router-dom";

import { ReactComponent as RailcardIcon } from '../../../images/railcard.svg';
import { ReactComponent as InfoIcon } from '../../../images/info.svg';
import { ReactComponent as ArrowRight } from '../../../images/arrow-right.svg';
import IBasketRailcard from "../../../interfaces/BasketRailcardState";
import priceUtils from '../../../utils/priceUtils';
import './Railcard.scss';
import { APP_PAGES, RAILCARD_CODES, TRACKING_EVENTS, TRACKING_STEPS } from '../../../constants';

import { trackEvent, getRailcardEventPayload } from '../../../utils/googleTagManager';
import { selectConfig } from "../../../features/config/configSlice";
import ConfigState from "../../../interfaces/ConfigState"


interface IRailcardView  {
  chosenPlan?: number,
  railcard: IBasketRailcard,
  railcardsFilterAge?: string,
}

const getRailcardSelectButtonAriaTag = (railcard, duration) => {
  return `select the ${railcard.railcardName} with validity for ${duration} year${duration === 1 ? '' : 's'}`;
}

export default function Railcard({ railcardsFilterAge, railcard, chosenPlan }: IRailcardView) {
  const { 
    titleFontColor,
    titleBgColor,
    oneYear,
    oneYearPrice,
    threeYear,
    threeYearPrice,
    uniqueSellingPoints,
    railcardCode,
    railcardName 
  } = railcard;

  const dispatch = useDispatch();

  const showBestValueLabel = (railcardsFilterAge === '16-17') && (railcardCode === RAILCARD_CODES['16-17']);

  const [showInfoModal, setShowInfoModal] = useState(false);

  const handleOpenInfoModal = () => {
    setShowInfoModal(true);
  };

  const handleCloseInfoModal = () => {
    setShowInfoModal(false);
  };

  const styles = {
    fill: titleFontColor,
    color: titleFontColor,
    background: titleBgColor,
    cursor: 'pointer',
  };

  const bestValueLabelStyles = {
    background: titleBgColor,
    borderRadius: '17px',
    padding: '3px 12px',
  };

  const isSelectMode = !chosenPlan;

  const { brand } = useSelector<ConfigState, ConfigState>(selectConfig);

  const selectRailcard = (chosenPlan: number) => {
    dispatch({ type: 'basket/selectRailcard', payload: {
      railcard, 
      chosenPlan
    } });

    const payload = getRailcardEventPayload({ railcard, chosenPlan });

    trackEvent({
      brand,
      eventName: TRACKING_EVENTS.ADDED_TO_BASKET,
      payload,
      step: TRACKING_STEPS.ORDER_STARTED,
    });
  };

  const removeRailcard = () => {
    dispatch({ type: 'basket/selectRailcard' });

    const payload = getRailcardEventPayload({ railcard, chosenPlan });
    trackEvent({
      brand,
      eventName: TRACKING_EVENTS.REMOVED_FROM_BASKET,
      payload,
      step: TRACKING_STEPS.BASKET_EMPTY,
    });
  }

  const renderSelectButtons = (showBestValueLabel) => (
    <>
      { 
        oneYear 
          && 
        <Link aria-label={getRailcardSelectButtonAriaTag(railcard, 1)} id={`select-${toLower(railcardCode)}-1-year`} to={APP_PAGES.USER_INFO} className='railcard-item__select-button' onClick={() => selectRailcard(1)} >
          <span>1 year for { priceUtils.formatPrice(oneYearPrice) } </span> 
          {showBestValueLabel && <div style={bestValueLabelStyles}>Best value</div>}
          <ArrowRight />
        </Link> 
      }

      {
        threeYear 
          && 
        <Link aria-label={getRailcardSelectButtonAriaTag(railcard, 3)} id={`select-${toLower(railcardCode)}-3-year`}to={APP_PAGES.USER_INFO} className='railcard-item__select-button' onClick={() => selectRailcard(3)} >
          <span>3 years for { priceUtils.formatPrice(threeYearPrice) } </span> <ArrowRight />
        </Link> 
      }
      
    </>
  );

  const renderFooter = () => {
    return <footer>
      <hr />
      <div className='footer-content'>
        <span>{chosenPlan} {chosenPlan === 1 ? 'year' : 'years'}</span>
        <span>{ priceUtils.formatPrice(chosenPlan === 1 ? oneYearPrice : threeYearPrice) }</span>
      </div>
    </footer>
  };

  const CustomTag = isSelectMode ? 'li' : 'div';

  return (
    <CustomTag className='railcard-item-wrapper'>
      <div className={`railcard-item ${!isSelectMode && 'railcard-item--preview'}`}>
        <header style={styles}>
          <h2 className="heading">
            <RailcardIcon style={styles} />
            <span>{railcardName}</span>
          </h2>
          <Button className="railcard-item__more-info-button" variant="hollow" onClick={handleOpenInfoModal} aria-label={`Show more information and details about the ${railcard.railcardName} railcard`}>
            <InfoIcon style={styles}/>
          </Button>
        </header>

        <div className='railcard-item__body'>
          <ul>
            {uniqueSellingPoints.map((usp, i) => <li key={i}>{usp}</li>)}
          </ul>

          { isSelectMode ? renderSelectButtons(showBestValueLabel) : renderFooter() }
        </div>
      </div>

      { !isSelectMode &&
        <Link aria-label={`Remove ${railcard.railcardName} from basket and return to homepage`} role="remove-railcard-from-basket" to={APP_PAGES.HOMEPAGE} className='railcard-item__remove-button' onClick={removeRailcard}>
          Remove from basket
        </Link>
      }

      <Modal className="railcard-info-modal" show={showInfoModal} onHide={handleCloseInfoModal}>
        <Modal.Header closeButton>
          <Modal.Title>{railcard.railcardName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { 
            /* 
              The below is not vulnerable to malicious code injection
              because we have sanitised the input with DOMPurify
            */
          }
          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(railcard.info, { ADD_ATTR: ['target'] })}}></div>
        </Modal.Body>
      </Modal>
    </CustomTag>
    
  );
}
