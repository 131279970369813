import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import TickIcon from '../../components/icons/TickIcon';
import DownloadLinks from '../../components/downloadLinks/DownloadLinks';
import { selectConfig } from '../../features/config/configSlice';
import { useTranslation } from 'react-i18next';
import { BOOKING_ENGINE_URLS } from '../../constants';
import './OrderSummary.scss';
import ConfigState from '../../interfaces/ConfigState';


export const getPlanJourneyLink = (config) => {
  const { baseURL, brand, environment } = config;

  let link = null;
  
  if (environment === 'dev' || environment === 'sid' ||  environment === 'stage') {
    link = `https://${brand}.${baseURL}/search`
  } else {
    link =  BOOKING_ENGINE_URLS[brand];
  }

  return link;
};

export const planJourney = (config) => {
  const link = getPlanJourneyLink(config);
  window.location.replace(link);
};

export default function OrderSummary() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const config = useSelector<ConfigState, ConfigState>(selectConfig);
  const { brand: lng } = config;

  useEffect(() => {
    // unmount
    return () => {
      // reset order, pending railcard etc
      dispatch({ type: 'pendingRailcard/reset' });
      dispatch({ type: 'pay360/reset' });
    }
  }, []);

  return <div className="order-summary">
      <TickIcon className="tick-icon" fill="#007D08" />
      <article>
        <section>
          <h1>Order complete</h1>
          <p>Thank you for buying your Digital Railcard with {t('brand', {lng})}, you will receive an order confirmation email shortly.</p>
          <p>Your Digital Railcard will be automatically added to your {t('brand', {lng})} app.</p>
          <p>Go to the Digital Railcard menu option in the app, from here you can Favourite your Digital Railcard so we can automatically apply your railcard discount every time you buy tickets.</p>
          <p>You can start using your Digital Railcard straight away!</p>
          <p>If you don't already have the {t('brand', {lng})} app, download it now.</p>
        </section>
        <footer>
          <DownloadLinks />
          <Button className="plan-a-journey-button mt-4" onClick={() => planJourney(config)} variant="primary">Plan a journey</Button>
        </footer>
      </article>
    </div>
}
