import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  apiAccessToken: null,
  baseURL: null,
  brand: null,
  enableCardPayments: true,
  environment: null,
  isDev: false,
  isSid: false,
  pay360Enabled: true,
  maintenanceMode: false,
  payPalEnabled: true,
};

export const configSlice = createSlice({
  name: 'config',

  initialState,

  reducers: {
    initialise: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      const {
        apiAccessToken,
        baseURL,
        brand,
        enableCardPayments,
        payPalEnabled,
        environment,
        isDev,
        maintenanceMode,
      } = action.payload.config;


      state.apiAccessToken = apiAccessToken;
      state.baseURL = baseURL;
      state.brand = brand;
      state.environment = environment;
      state.isDev = isDev;
      state.pay360Enabled = enableCardPayments;
      state.payPalEnabled = payPalEnabled;
      state.maintenanceMode = maintenanceMode;
    },
  },
});

export const selectConfig = (state) => state.config;

export default configSlice.reducer;
