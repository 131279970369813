import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  loginModalOpen: false,
  registerModalOpen: false,
  passwordResetModalOpen: false,
  errorModalOpen: false,
  errorModalMessage: null,
  errorModalTitle: null,
};

export const globalModalsSlice = createSlice({
  name: 'globalModals',
  initialState,
  reducers: {
    openLoginModal: (state) => {
      state.loginModalOpen = true;
      state.registerModalOpen = false;
      state.passwordResetModalOpen = false;
    },
    closeLoginModal: (state) => {
      state.loginModalOpen = false;
    },
    openRegisterModal: (state) => {
      state.loginModalOpen = false;
      state.registerModalOpen = true;
      state.passwordResetModalOpen = false;
    },
    closeRegisterModal: (state) => {
      state.registerModalOpen = false;
    },
    openPasswordResetModal: (state) => {
      state.loginModalOpen = false;
      state.registerModalOpen = false;
      state.passwordResetModalOpen = true;
    },
    closePasswordResetModal: (state) => {
      state.passwordResetModalOpen = false;
    },
    openErrorModal: (state, action) => {
      state.errorModalOpen = true;
      state.errorModalMessage = action.payload.message;
      state.errorModalTitle = action.payload.title;
    },
    closeErrorModal: (state) => {
      state.errorModalOpen = false;
    }
  },
});

export const selectGlobalModals = (state) => state.globalModals;

export default globalModalsSlice.reducer;
