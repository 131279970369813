import React from 'react';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';


function PaymentError ({ message = 'Unknown error' }) {
    const dispatch = useDispatch();

    const onRetry = () => {
        dispatch({ type: 'pay360/createSession'});
    };

    return (
        <div className="confirmation text-center js-payment-result" data-error={message}>
            <Icon.XCircleFill size={48} color="#b83116" />
            <p className="text-danger mt-3 text-weight--normal">Sorry, your payment has been declined for the following reason</p>
            <p className="text-sm margin-bottom">{ message }</p>
            {onRetry && (
                <Button bsStyle="default" onClick={ onRetry }>
                    Retry
                </Button>
            ) }
        </div>
    );
}

export default PaymentError;
