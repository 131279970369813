import createSagaMiddleware from 'redux-saga';
import { fork } from 'redux-saga/effects'
import { configureStore } from '@reduxjs/toolkit';
import basketReducer from '../features/basket/basketSlice';
import configReducer from '../features/config/configSlice';
import discountReducer from '../features/discount/discountSlice';
import documentsReducer from '../features/documents/documentsSlice';
import globalModalsReducer from '../features/globalModals/globalModalsSlice';
import loginReducer from '../features/login/loginSlice';
import railcardsReducer from '../features/railcards/railcardsSlice';
import passwordResetReducer from '../features/passwordReset/passwordResetSlice';
import pay360Reducer from '../features/pay360/pay360Slice';
import paypalReducer from '../features/paypal/paypalSlice';
import pendingRailcardReducer from '../features/pendingRailcard/pendingRailcardSlice';
import registerReducer from '../features/register/registerSlice';
import userReducer from '../features/user/userSlice';
import discountSaga from '../sagas/discount';
import loginSaga from '../sagas/login';
import logoutSaga from '../sagas/logout';
import passwordResetSaga from '../sagas/passwordReset';
import pay360Saga from '../sagas/pay360';
import paypalSaga from '../sagas/paypal';
import railcardsSaga from '../sagas/railcards';
import registerSaga from '../sagas/register';
import { loadFromLocalStorage, saveToLocalStorage } from '../utils/localStorage';


const logger = store => next => action => {
  console.group(action.type);
  console.info('dispatching', action);
  const result = next(action);
  console.log('next state', store.getState());
  console.groupEnd();
  return result;
}

export default function* rootSaga() {
  yield fork(discountSaga)
  yield fork(loginSaga)
  yield fork(logoutSaga)
  yield fork(passwordResetSaga)
  yield fork(pay360Saga)
  yield fork(paypalSaga)
  yield fork(railcardsSaga)
  yield fork(registerSaga)
}

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: { 
    basket: basketReducer,
    config: configReducer,
    discount: discountReducer,
    documents: documentsReducer,
    globalModals: globalModalsReducer,
    login: loginReducer,
    passwordReset: passwordResetReducer,
    pay360: pay360Reducer,
    paypal: paypalReducer,
    pendingRailcard: pendingRailcardReducer,
    railcards: railcardsReducer,
    register: registerReducer,
    user: userReducer,
  },
  preloadedState: loadFromLocalStorage('state'),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware).concat(logger),
});

store.subscribe(() => {
  // I'm not sure if we should throttle this callback, I don't think it will be fired that often
  saveToLocalStorage('state', store.getState());
})

// export type RootState = ReturnType<typeof store.getState>

sagaMiddleware.run(rootSaga);
