import { call, delay, put, takeLatest, select } from 'redux-saga/effects'
import { invertObj, replace } from 'ramda';
import railcardsService from '../services/railcards';
import { selectConfig } from '../features/config/configSlice';
import { selectDocuments } from '../features/documents/documentsSlice';
import { selectUser, selectUserAccessToken } from '../features/user/userSlice';
import railcardUtils from '../utils/railcardUtils';
import { APP_PAGES, API_ERROR_TYPES, RAILCARD_EXPIRATIONS, TRACKING_EVENTS, TRACKING_STEPS, API_DOCUMENT_TYPES } from '../constants';
import { getRailcardEventPayload, trackEvent } from '../utils/googleTagManager';
import ageValidationUtils from '../utils/ageValidationUtils';
import moment from 'moment';

export function* getRailcards() {
  try {
      const config = yield select(selectConfig);
      yield delay(1500); // make request take at least a short time so UI doesn't blink
      const { railcards } = yield call(railcardsService.getRailcards, config);
      yield put({type: "railcards/getRailcardsSuccess", payload: railcards});
  } catch (e) {
      yield put({type: "railcards/getRailcardsFailure", message: e.message});
  }
}

export function* createPendingRailcard({ payload: { isRenewing, navigate, railcardData, renewedRailcardId } }) {
  const config = yield select(selectConfig);

  const { 
    customerId,
    railcardInfo,
    userInfo,
    primaryPhoto,
    secondaryPhoto,
  } = railcardData;

  const documentInfo = yield select(selectDocuments);

  let token = yield select(selectUserAccessToken);

  try {
    yield put({type: "pendingRailcard/resetError"});

    let response;

    if (isRenewing) {
      response = yield call(railcardsService.renewRailcard, {
        config, customerId, railcardInfo, userInfo, documentInfo, primaryPhoto, renewedRailcardId, secondaryPhoto, token
      });
    } else {
      response = yield call(railcardsService.createPendingRailcard, {
        config, customerId, railcardInfo, userInfo, documentInfo, primaryPhoto, secondaryPhoto, token
      });
    }

    const { railcards: createdRailcard } = response;

    if (railcardUtils.validatePendingRailcard(createdRailcard)) {
      yield put({type: "pendingRailcard/createPendingRailcardSuccess"});
      yield put({type: "pendingRailcard/setPendingRailcard", payload: createdRailcard});
    
      const payload = getRailcardEventPayload({ railcard: createdRailcard, chosenPlan: parseInt(replace('Y', '', createdRailcard.railcardExpirationLength)) });
    
      trackEvent({
        brand: config.brand,
        eventName: TRACKING_EVENTS.PHOTO_UPLOAD,
        payload,
        railcardId: createdRailcard.id,
        step: Object.assign(TRACKING_STEPS.PHOTO_UPLOAD, { stepName: createdRailcard.id }),
      })
  
      navigate(APP_PAGES.PAYMENT);
    } else {

      const errorType = createdRailcard?.errors?.errorType;

      /*
        Expired user token/session
        Log user out and send user back to homepage
      */
      if (errorType === API_ERROR_TYPES.AUTH_ERROR) {
        yield put({type: "globalModals/openErrorModal", payload: {
          message: 'Your session has expired and your basket has been cleared. Please log in and try again.',
          title: 'Error creating railcard'
        }});
        yield put({ type: 'login/userLogout'});
        navigate(APP_PAGES.HOMEPAGE);      
      } else if (errorType === API_ERROR_TYPES.VALIDATION_ERROR) {
        yield put({type: "globalModals/openErrorModal", payload: {
          message: 'Sorry, something has gone wrong when attempting to create your new railcard. Check if you entered your date of birth correctly so that you are eligible for this railcard and try again.',
          title: 'Error creating railcard'
        }});
      // Something else
      } else {
        yield put({type: "globalModals/openErrorModal", payload: {
          // Don't expose potentially confusing/highly technical error message to the user
          message: 'Sorry, something has gone wrong when attempting to create your new railcard',
          title: 'Error creating railcard'
        }});
      }

      throw new Error('Failed to create pending railcard', createdRailcard?.errors?.message);
    }
  } catch (e) {
    console.log('ERROR ERROR ERROR', e);
    yield put({type: "pendingRailcard/createPendingRailcardFailure", message: 'An error occured creating your railcard'});
  }
}

export function* getRailcard(railcardId, token) {
  const config = yield select(selectConfig);
  const railcard = yield call(railcardsService.getRailcard, config, railcardId, token);
  return railcard;
}

export function* updateRailcardRenewalPreference({ payload: { railcard, sendRenewalNotification }}) {
  const user = yield select(selectUser);
  const config = yield select(selectConfig);
  let token = yield select(selectUserAccessToken);

  const customerId = user.id;
  const railcardId = railcard.id;

  try {
    yield call(railcardsService.updatePendingRailcardRenewalPreference, { config, customerId, sendRenewalNotification, railcardId, token });
    
    const updatedRailcard = yield call(getRailcard, railcardId, token);
    
    yield put({type: 'pendingRailcard/setPendingRailcard', payload: updatedRailcard});
  } catch (e) {
    // console.log('error updating railcard renewal preference', e);
  }
}

export function* renewRailcard({ payload: { navigate, renewedRailcardId, railcards }}) {
  let token = yield select(selectUserAccessToken);

  try {   
    const railcard = yield call(getRailcard, renewedRailcardId, token);

    const { day, month, year } = ageValidationUtils.splitDate(railcard.dateOfBirth);

    const customerInfo = {
      title: railcard.primaryTitle,
      firstName: railcard.primaryFirstName,
      lastName: railcard.primaryLastName,
      dateOfBirth: {
        day,
        month,
        year
      },
      secondHolderTitle: railcard.secondaryTitle || '',
      secondHolderFirstName: railcard.secondaryFirstName || '',
      secondHolderLastName: railcard.secondaryLastName || '', 
    };

    const documentType = invertObj(API_DOCUMENT_TYPES)?.[railcard.documentType] || '';

    const documentData = {
      documentType,
      documentNumber: railcard.documentNumber,
    }

    const chosenPlan = railcard.railcardExpirationLength === RAILCARD_EXPIRATIONS.THREE_YEARS ? 3 : 1;
    const validUntil = moment(railcard.validUntil).format('YYYY-MM-DD');

    const selectedRailcard = railcards.filter(card => card.railcardCode === railcard.railcardCode)?.[0];
    
    if (!selectedRailcard) {
      navigate(APP_PAGES.HOMEPAGE);
      throw new Error('Invalid railcard type to renew');
    }

    yield put({type: 'basket/renewRailcard', payload: {
      chosenPlan,
      customerInfo,
      renewedRailcardId,
      selectedRailcard,
      primaryPhotoUUID: railcard?.primaryPhotoUUID,
      secondaryPhotoUUID: railcard?.secondaryPhotoUUID,
      validUntil
    }});
    yield put({type: 'documents/setDocuments', payload: { data: documentData }});

    navigate(APP_PAGES.RAILCARD_PHOTO);
  } catch (e) {
    console.log('error getting customer railcards', e);
  }
}

function* railcardsSaga() {
  yield takeLatest("railcards/getRailcards", getRailcards);
  yield takeLatest("railcards/createPendingRailcard", createPendingRailcard);
  yield takeLatest("railcards/updateRailcardRenewalPreference", updateRailcardRenewalPreference);
  yield takeLatest("railcards/renewRailcard", renewRailcard);
}

export default railcardsSaga;
