import * as React from "react";
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Yup from 'yup';
import { selectRegister } from '../../registerSlice';
import IRegisterState from '../../../../interfaces/RegisterState';
import { CustomerTitles, GENERAL_TERMS_URLS, PRIVACY_POLICY_URLS } from '../../../../constants';
import TickIcon from '../../../../components/icons/TickIcon';
import { useTranslation } from 'react-i18next';
import ConfigState from "../../../../interfaces/ConfigState";
import { selectConfig } from '../../../config/configSlice';

import '../../../../styles/login-and-register.scss';

interface RegisterFormValues {
  title: string;
  firstNames: string;
  surname: string;
  email: string;
  password: string;
  isOver16: boolean;
  travelUpdates: boolean;
  products: boolean,
  promotions: boolean;
}

const SignupSchema = Yup.object().shape({
  title: Yup.string().oneOf(CustomerTitles).required('Please enter your title'),
  firstNames: Yup.string()
    .min(2, 'Title must be 2 or more characters long')
    .max(50, 'Title must be 50 characters or fewer')
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "Please only enter letters A-Z"
      )
    .required('Please enter your first name(s)'),
  surname: Yup.string()
    .min(2, 'First name(s) must be 2 or more characters long')
    .max(50, 'First name(s) must be 50 characters or fewer')
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "Please only enter letters A-Z"
      )
    .required('Please enter your surname'),
  email: Yup.string().email('Invalid email').required('Please enter your email'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .max(120, 'Password must be 120 characters or fewer')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      "Must contain at least one uppercase letter, one lowercase leter and at least one number"
    )
    .required('Please enter a password'),
});


const RegisterForm = ({ handleShowLoginModal, handleSubmit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { brand: lng } = useSelector<ConfigState, ConfigState>(selectConfig);

  const initialValues: RegisterFormValues = { 
    title: 'Mr',
    firstNames: '',
    surname: '',
    email: '',
    password: '' ,
    isOver16: false,
    travelUpdates: false,
    products: false,
    promotions: false,
  };

  const { error: registrationError, success } = useSelector<IRegisterState, IRegisterState>(selectRegister);

  const [step, setStep] = React.useState(1);

  const handleNext = () => {
    setStep(2);
  };

  React.useEffect(() => {
    dispatch({ type: 'register/resetForm' });
  }, [dispatch]);

  return (<div className="register-form">
    <Formik
      initialValues={initialValues}

      validationSchema={SignupSchema}

      validateOnMount={true}

      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
        }, 400);
        handleSubmit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        const isDisabled = isSubmitting || !(isEmpty(errors));

        const nextStep = (e) => {
          e.preventDefault();
          if (step === 1) {
            handleNext();
          } else {
            handleSubmit();
          }
        }

        return (
          <Form onSubmit={handleSubmit}>
            {!success && step === 1 && (
              <React.Fragment>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="title">
                      <Form.Label>Title *</Form.Label>
                      <Form.Select 
                        aria-label="Please enter your title" 
                        value={values.title}
                        name="title"
                        onChange={handleChange}
                      >
                        {CustomerTitles.map(title => {
                          return (<option value={title}>{title}</option>);
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="firstNames">
                      <Form.Label>First name *</Form.Label>
                      <Form.Control 
                        name="firstNames"
                        type="firstNames"
                        aria-describedby="firstNames-error-message"
                        placeholder="First name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstNames}
                      />
                      <Form.Text id="firstNames-error-message" role="alert" className="form-text">
                        {errors.firstNames && touched.firstNames && errors.firstNames}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="surname">
                      <Form.Label>Surname *</Form.Label>
                      <Form.Control 
                        name="surname"
                        type="surname"
                        aria-describedby="surname-error-message"
                        placeholder="Surname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.surname}
                      />
                      <Form.Text id="surname-error-message" role="alert" className="form-text">
                        {errors.surname && touched.surname && errors.surname}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>Email *</Form.Label>
                      <Form.Control 
                        name="email"
                        type="email"
                        aria-describedby="email-error-message"
                        placeholder="Email address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <Form.Text id="email-error-message" role="alert" className="form-text">
                        {errors.email && touched.email && errors.email}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="id">
                      <Form.Label>Password *</Form.Label>
                      <Form.Control 
                        name="password"
                        type="Password"
                        aria-describedby="password-error-message"
                        placeholder="Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      <Form.Text id="password-error-message" role="alert" className="form-text">
                        {errors.password && touched.password && errors.password}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              </React.Fragment>
            )}

            {!success && step === 2 && (
              <React.Fragment>
                <p><em>We'd like to keep you updated about changes to your service, ticket renewals and special offers. Please let us know below which emails you'd like to receive.</em></p>
                <Form.Group>
                  <legend>Your age</legend>
                  <Form.Check 
                    type="checkbox"
                    id="isOver16"
                    label="I am 16 or over"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <legend>Notification preferences</legend>
                  <Form.Check 
                    type="checkbox"
                    id="travelUpdates"
                    label="Travel updates"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <Form.Check 
                    type="checkbox"
                    id="promotions"
                    label={`${t('brand', {lng})} promotions, rewards and offers`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <Form.Check 
                    type="checkbox"
                    id="products"
                    label="New products and services"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>

                <footer className="mt-3">
                  <p><em>By clicking “Create my account” you confirm that you have read, and that you accept, our <a href={GENERAL_TERMS_URLS[lng]}>Terms &amp; Conditions</a> and <a href={PRIVACY_POLICY_URLS[lng]}>Privacy Policy</a>.</em></p>
                  <p><em>We will not pass on your personal information to any organisation outside of {t('brand', {lng})}. Should we no longer operate the {t('brand', {lng})} franchise, please be aware that we will pass your details to the Successor franchise or Secretary of State for Transport to allow you to continue using your customer account and receive marketing emails if you have consented to them.</em></p>
                </footer>
              </React.Fragment>
            )}

            {!success && <Button onClick={nextStep} type="submit" disabled={isDisabled} className="login-modal__save" variant="primary">
                {step === 1 ? 'Next' : 'Register'}
            </Button>}

            {success && <div className="register-form__success">
              <TickIcon />
              <h3 className="register-form__success-heading">Welcome to {t('brand', {lng})}</h3>
            </div>}

            {registrationError && (<Alert className="error-well" variant="danger">
              <p>{registrationError}</p>
            </Alert>)}

            {!success && <footer className="login-modal__actions mt-3">
              <div className="row">
                <div className="column">
                  <p>Already have an account? <Button onClick={handleShowLoginModal} className="login-modal__actions__button" variant="link">Log in</Button></p>
                </div>
              </div>
            </footer>}
          </Form>
        )
      }}
    </Formik>
  </div>);
};

export default RegisterForm;
