import React, { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import userInterfaceUtils from '../../../../utils/userInterfaceUtils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ConfigState from '../../../../interfaces/ConfigState';
import { selectConfig } from '../../../../features/config/configSlice';

import './FAQ.scss';

export default function FAQ() {
    const { brand: lng } = useSelector<ConfigState, ConfigState>(selectConfig);
    const { t } = useTranslation();

    useEffect(() => {
        userInterfaceUtils.deepLinkAndSmoothScrollToPageElement('railcard-faqs');
    }, []);

    return (
        <section id="railcard-faqs" className='homepage-faq'>
            <h1>Digital Railcard <span aria-label="Frequently Asked Questions">FAQ</span>s</h1>
            <Accordion alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>What is a Digital Railcard?</Accordion.Header>
                    <Accordion.Body>
                        <p>Digital Railcards are a convenient alternative to traditional paper Railcards. </p>
                        <p>Instead of having to bring your paper railcard with you every time you travel, Digital Railcards are stored in the {t('brand', {lng})} app. So your tickets and Railcards will be stored in the same place. You can buy Digital Railcards from the {t('brand', {lng})} website and app.</p>
                        <p>Digital Railcards offer the same great savings as paper railcards, they&apos;re just easier to buy, store and use. </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>How do I use my Digital Railcard?</Accordion.Header>
                    <Accordion.Body>
                        <p>Just download the {t('brand', {lng})} app to store your Digital Railcard.</p>
                        <p>You can buy your Digital Railcard from the {t('brand', {lng})} website or app, and it will automatically be available in your account.</p>
                        <p>The first time you buy it, remember to Favourite it in the {t('brand', {lng})} app so we can automatically apply your Railcard discount every time you buy tickets with us.</p>
                        <p>If a member of staff asks to see your Railcard, just show them your Digital Railcard in the {t('brand', {lng})} app.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>How do I renew my Digital Railcard?</Accordion.Header>
                    <Accordion.Body>
                        <p>We will send you an email or app message before your railcard is due to expire.</p>
                        <p>You can renew your Digital Railcard through the {t('brand', {lng})} website or app, within 30 days of the expiry date.</p>
                        <p>Simply login to your account, select the type of railcard you want to renew, then follow the easy steps.</p>
                        <p>Once your payment has been confirmed, your renewed Digital Railcard will be available to use straight away.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Can I get a refund on my Digital Railcard?</Accordion.Header>
                    <Accordion.Body>
                        <p>Sorry, Digital Railcards aren&apos;t refundable and they also can&apos;t be transferred to another person.</p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </section>
    );
  }
  