import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { replace } from 'ramda';
import Button from "react-bootstrap/esm/Button";
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { selectPay360 } from '../../features/pay360/pay360Slice';
import { selectPaypal } from '../../features/paypal/paypalSlice';
import { selectBasket } from '../../features/basket/basketSlice';
import { selectPendingRailcard } from '../../features/pendingRailcard/pendingRailcardSlice';
import { selectConfig } from '../../features/config/configSlice';
import { selectUser } from '../../features/user/userSlice';
import { APP_PAGES, RAILCARD_TERMS_URLS, TRACKING_EVENTS, TRACKING_STEPS } from '../../constants';
import BasketState from "../../interfaces/BasketState";
import PendingRailcardState from "../../interfaces/PendingRailcardState";
import Pay360State from "../../interfaces/Pay360State";
import PaypalState from "../../interfaces/PaypalState";
import UserState from "../../interfaces/UserState";
import ConfigState from "../../interfaces/ConfigState";
import PaymentCardHosted from './components/PaymentCardHosted';
import Loader from "../../components/loader/Loader";
import { Pay360SessionState } from '../../constants';
import BasketSummary from './components/BasketSummary';
import PaypalLogo from "../../images/paypal-logo.png";
import { trackEvent, getRailcardEventPayload } from '../../utils/googleTagManager';
import './Payment.scss';


export default function Payment() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { initAuthFlow, processing: paypalProcessing, ready: paypalReady } = useSelector<PaypalState, PaypalState>(selectPaypal);

  const { loggedIn } = useSelector<UserState, UserState>(selectUser);

  const { brand, pay360Enabled, payPalEnabled } = useSelector<ConfigState, ConfigState>(selectConfig);

  const basket = useSelector<BasketState, BasketState>(selectBasket);
  const pendingRailcard = useSelector<PendingRailcardState, PendingRailcardState>(selectPendingRailcard);

  const { error, paidFor: orderPaid, railcard } = pendingRailcard;

  const [termsAccepted] = useState(true); // implicit agreement when they hit 'pay'

  const railcardTermsUrl =  RAILCARD_TERMS_URLS[brand];

  const { 
    paymentProcessing,
    paymentProcessingFailed,
    paymentState,
    session,
    sessionState,
    transactionState
  } = useSelector<Pay360State, Pay360State>(selectPay360);
  
  const redirectUrl = session?.redirectUrl;

  const [noRailcardFound, setNoRailcardFound] = useState(false);

  const {
    chosenPlan,
    selectedRailcard: basketRailcard,
  } = basket;

  let basketAmount = 0;

  if (chosenPlan === 1) {
    basketAmount = basketRailcard?.oneYearPrice;
  } else {
    basketAmount = basketRailcard?.threeYearPrice;
  }

  const payByPaypal = () => {
    dispatch({type: 'paypal/checkout' });
    initAuthFlow();
  };

  const backToHome = () => {
    navigate(APP_PAGES.HOMEPAGE);
  };

  const goBack = () => {
    navigate(APP_PAGES.RAILCARD_PHOTO);
  };

  useEffect(() => {
    if (railcard?.id) {
      dispatch({ type: 'paypal/setUpPayment', payload: {
        dispatch,
        amount: basketAmount,
      } });
  
      dispatch({ type: 'pay360/createSession'});
    }
  }, [basketAmount, dispatch, railcard]);

  useEffect(() => {
    if (!loggedIn) {
      navigate(APP_PAGES.HOMEPAGE);
    }
    // PayPal only, Pay360 handles this in the Sagas
    if (orderPaid) {
      const payload = getRailcardEventPayload({ railcard: basketRailcard, chosenPlan: parseInt(replace('Y', '', pendingRailcard.railcard.railcardExpirationLength)) });
      trackEvent({
        brand,
        eventName: TRACKING_EVENTS.PAID,
        payload,
        railcardId: pendingRailcard.railcard.id,
        step: Object.assign(TRACKING_STEPS.PAYMENT_SCREEN, { stepName: pendingRailcard.paidWith }),
      });
      navigate(APP_PAGES.ORDER_SUMMARY);
    }
  }, [navigate, orderPaid, railcard, loggedIn]);

  useEffect(() => {
    setTimeout(function () {
      if (!railcard) {
        setNoRailcardFound(true);
      }
      // if no pending railcard after 5 seconds, assume none created and user needs to start again
    }, 5000); 
  }, [railcard]);

  useEffect(() => {
    if (paymentState === Pay360SessionState.SUCCESS) {
      navigate(APP_PAGES.ORDER_SUMMARY);
    }
  }, [navigate, paymentState])

  useEffect(() => {
    dispatch({ type: 'pay360/reset'});
  }, [dispatch]);

  const paymentEnabled = railcard && termsAccepted;

  const handleRenewalNotificationPreferenceChange = () => {
    dispatch({ type: 'railcards/updateRailcardRenewalPreference', payload: {
      railcard,
      sendRenewalNotification: !railcard.sendRenewalNotification
    }});
  }

  return <div className='payment'>
    <div className="mt-5">
      <h2 className="payment__subheading">Basket</h2>
      {railcard && <BasketSummary railcard={railcard} />}

      <section className="payment__terms">
        <Form>
          <Form.Check 
            type="switch"
            id="custom-switch"
            label="Remind me via email and app notification when my railcard is about to expire"
            checked={railcard?.sendRenewalNotification}
            onChange={handleRenewalNotificationPreferenceChange}
          />
        </Form>
        <p>By buying this Digital Railcard, I accept the <a rel="external" className="external" target="_blank" href={railcardTermsUrl}>Railcard Terms and Conditions</a>. Please note that Digital Railcards are non-refundable.</p>
      </section>

      {termsAccepted && (<section>
        <h2 className="payment__subheading">Payments</h2>
        {paymentEnabled && <ul className="payment-options">
          {paypalReady && payPalEnabled && <li className="payment-options__option">
            {paypalProcessing ? <Loader
                message="Your payment is being processed"
                unwrapped={false}
                inline={false}
                children={[]}
              /> : <div className='paypal-cta-wrapper'>
                <img src={PaypalLogo} alt="" />
                <div>
                  <Button onClick={payByPaypal}>Pay Now</Button>
                </div>
            </div>}
          </li>}
          {pay360Enabled && (<li className="payment-options__option">
            <h3>Pay by card</h3>
            {session ? <PaymentCardHosted 
              redirectUrl={redirectUrl}
              basketAmount={basketAmount} 
              paymentProcessing={paymentProcessing}
              paymentProcessingFailed={paymentProcessingFailed}
              sessionState={sessionState}
              transactionState={transactionState}
            /> : <Loader
              message=""
              unwrapped={false}
              inline={false}
              children={[]}
            />}
          </li>)}
        </ul>}

        {(!railcard && !error && !noRailcardFound) && <div>
          <Loader
              message=""
              unwrapped={false}
              inline={false}
              children={[]}
          />
        </div>}
        {(!railcard && !error && noRailcardFound) && <div>
          <p>No railcard created for purchase. Please go back and select a railcard.</p>
          <Button className="back-to-home" onClick={backToHome} variant="primary">Start again</Button>
        </div>}
        {error && (<Alert className="mt-5" variant="danger">
          Sorry, an error has occurred while preparing your new railcard for payment. Please try again later.
        </Alert>)}
      </section>)}
    </div>

    <footer className='user-info__footer'>
      <Button variant="outline-primary" onClick={goBack}>Back</Button>
    </footer>

    <div id="paypal-container"></div>
  </div>
}
