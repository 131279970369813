import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import paypalService from '../services/paypal';
import { selectPaypal, selectClientToken } from '../features/paypal/paypalSlice';
import { selectUserAccessToken } from '../features/user/userSlice';
import { selectPendingRailcard } from '../features/pendingRailcard/pendingRailcardSlice';
import { selectBasket } from '../features/basket/basketSlice';
import { selectConfig } from '../features/config/configSlice';
import { selectIsProcessing } from '../features/paypal/paypalSlice';


function* getClientToken() {
  const config = yield select(selectConfig);
  const clientToken = yield call(paypalService.getClientToken, config);

  try {
    yield put({type: 'paypal/getClientTokenSuccess', payload: {
      clientToken
    }});
    return clientToken;
  } catch (e) {
    console.log('get paypal client token error', e);
    yield put({type: 'paypal/getClientTokenFailure', payload: clientToken });
  }
}

function* checkout ({ amount, railcardId, deviceData, nonce, token }) {
  const isProcesing = yield select(selectIsProcessing);

  // prevent duplicate payment calls
  if (isProcesing) {
    return false;
  }

  const config = yield select(selectConfig);

  try {
    yield put({type: 'paypal/checkoutProcessing' });

    const result = yield call(paypalService.checkout, { amount, railcardId, deviceData, nonce, token, config });

    const paymentSuccessful = result?.payment?.success;

    if (!paymentSuccessful) {
      yield put({type: "globalModals/openErrorModal", payload: {
        message: 'Sorry, an error occured attempting to check out with PayPal. Please try again later.',
        title: 'PayPal checkout error'
      }});
      console.log('PayPal checkout error');
      throw new Error('PayPal checkout error');
    } else {
      yield put({type: 'paypal/checkoutSuccess' });
      yield put({type: 'pendingRailcard/setPaid', payload: {
        paidWith: 'paypal'
      } });
    }
  } catch (e) {
    yield put({type: 'paypal/checkoutFailure' });
    // Hack, because this service doesnt' return proper AuthError responses
    const isAuthError = e.message.includes('The suppli');
    console.log('PayPal checkout error', e);

    if (isAuthError) {
      yield put({ type: 'login/userLogout'});
      yield put({type: "globalModals/openErrorModal", payload: {
        message: 'Your session has expired and your basket has been cleared. Please log in and try again.',
        title: 'Error during PayPal checkout'
      }});
    }
  }
}

function* paymentSuccess () {
  let { deviceData, nonce } = yield select(selectPaypal);

  let { railcard } = yield select(selectPendingRailcard);

  const basket = yield select(selectBasket);

  const {
    chosenPlan,
    selectedRailcard: basketRailcard,
  } = basket;

  let amount = 0;

  if (chosenPlan === 1) {
    amount = basketRailcard?.oneYearPrice;
  } else {
    amount = basketRailcard?.threeYearPrice;
  }

  const { id: railcardId } = railcard;

  let token = yield select(selectUserAccessToken);

  yield call(checkout, { amount, railcardId, deviceData, nonce, token });
}

function* setUpPayment ({ payload: args }) {
  try {
    const {
      dispatch,
      amount,
    } = args;

    const config = yield select(selectConfig);
    
    const handlePaymentMedthodReceived = (payload) => {
      dispatch({ type: 'paypal/paymentSuccess', payload });
    }
  
    let token = yield select(selectClientToken);

    if (!token) {
      token = yield call(getClientToken);
    }
  
    const setup = yield call(paypalService.setupSingleUse, {
      token, amount, handlePaymentMedthodReceived, config
    });
    const { paypal: { initAuthFlow, closeAuthFlow }, teardown, deviceData } = setup;
  
    const payload = {
      initAuthFlow,
      closeAuthFlow,
      teardown,
      deviceData
    };
  
    yield put({type: 'paypal/paypalReady', payload });
  } catch (e) {
    console.log('error setting up PayPal', e);
  }
}

function* paypalSaga() {
  yield takeLatest('paypal/getClientToken', getClientToken);
  yield takeLatest('paypal/setUpPayment', setUpPayment);
  yield takeEvery('paypal/paymentSuccess', paymentSuccess);
}

export default paypalSaga;
