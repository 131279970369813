import React from 'react';
import useEvent from 'react-use/lib/useEvent';
import Loader from "../../../components/loader/Loader";


const IFRAME_INITIAL_HEIGHT = 400;
const PAY360_ORIGIN = '.pay360.com';


export default function Pay360SessionIframe({
    src, 
    onComplete = () => false,
}) {
    // Handle window.PostMessage events from the "skin" and the redirectUrl callback
    const [height, setHeight] = React.useState(IFRAME_INITIAL_HEIGHT);

    const [isLoading, setLoading] = React.useState(true);

    const handleMessageEvent = React.useCallback(
        async event => {
            const {origin, data} = event;

            if (!data.src === 'ontrack:pay360') {
                return;
            }

            // Iframe contains a booking-engine URL (e.g. pay360SessionReturnUrl)
            if (origin === window.location.origin) {
                // Transaction either succeeded, failed or was cancelled,
                // we need to fetch the session to find out what exactly
                if (data.event === 'SESSION_COMPLETE') {
                    onComplete();
                }
            }

            // The message is coming from the custom skin
            if (origin.endsWith(PAY360_ORIGIN)) {
                // The iframe content changed, causing size to change
                if (data.event === 'RESIZE') {
                    setHeight(data.height);
                }

                // Iframe content navigation
                if (data.event === 'UNLOAD') {
                    setLoading(true);
                }

                // Iframe content navigation
                if (data.event === 'LOAD') {
                    setLoading(false);
                }
            }
        },
        [onComplete],
    );

    useEvent('message', handleMessageEvent, window);

    return (
        <div className="position--relative">
            {isLoading && <Loader
                message=""
                unwrapped={false}
                inline={false}
                children={[]}
              />}
            <iframe
                id="pay360Iframe"
                src={src}
                style={{ width: '100%', height: `${height}px`, border: 'none' }}
                onLoad={() => setLoading(false)}
                title="Pay360 iFrame"
            />
        </div>
    );
}
