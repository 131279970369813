import { Route } from "react-router-dom";

import { APP_PAGES } from "../constants";

import Homepage from '../features/homepage/Homepage';
import BasicInfo from '../features/user/userInfo/basicInfo/BasicInfo';
import NotFound from '../features/notFound/NotFound';
import ProofOfAge from '../features/user/userInfo/proofOfAge/ProofOfAge';
import RailcardPhoto from '../features/user/userInfo/railcardPhoto/RailcardPhoto';
import Payment from '../features/payment/Payment';
import OrderSummary from '../features/order/Summary';


const routes = [
    {
        path: APP_PAGES.HOMEPAGE,
        element: <Homepage />,
    },
    {
        path: APP_PAGES.RENEW,
        element: <Homepage />,
        exact: {},
    },
    {   // Deep linking into the homepage railcard options
        path: APP_PAGES.RAILCARD_OPTIONS,
        element: <Homepage />, 
    },
    {   // Deep linking into the homepage railcard FAQs
        path: APP_PAGES.RAILCARD_FAQS,
        element: <Homepage />, 
    },
    
    {   // Deep linking into the homepage railcard FAQs
        path: APP_PAGES.CHOOSE_RAILCARD,
        element: <Homepage />, 
    },
    {
        path: APP_PAGES.USER_INFO,
        element: <BasicInfo />
    },
    {
        path: APP_PAGES.PROOF_OF_AGE,
        element: <ProofOfAge />
    },
    {
        path: APP_PAGES.RAILCARD_PHOTO,
        element: <RailcardPhoto />
    },
    {
        path: APP_PAGES.PAYMENT,
        element: <Payment />
    },
    {
        path: APP_PAGES.ORDER_SUMMARY,
        element: <OrderSummary />
    },
    {
        path: APP_PAGES.NOT_FOUND,
        element: <NotFound />
    }
]

export default routes.map(route => <Route key={route.path} {...route} />);
