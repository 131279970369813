import React from 'react';
import PaymentError from './PaymentError';
import Loader from "../../../components/loader/Loader";


function Pay360SessionDetails({
        processingFailed,
        paymentFailed, 
    }) {

    if (processingFailed) {
        // Generic message, no retry
        // Possibly money was taken (pay360 says success) - but cocs/payment didn't transition order to "complete"
        return <PaymentError />;
    }

    if (paymentFailed) {
        // Session aborted/failed and can be restarted
        return (
            <PaymentError
                message={paymentFailed}
            />
        );
    }

    // Otherwise, we are in some kind of waiting/transition state
    return <Loader
        unwrapped={false}
        inline={false}
        children={[]}
    />
}

export default Pay360SessionDetails;
