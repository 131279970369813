import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  railcard: null,
  paidFor: false,
  paidWith: null,
  error: null,
};

export const pendingRailcardSlice = createSlice({
  name: 'pendingRailcard',
  initialState,
  reducers: {
    setPendingRailcard: (state, action) => {
      state.railcard = action.payload;
    },
    setPaid: (state, action) => {
      state.paidFor = true;
      state.paidWith = action.payload.paidWith;
    },
    reset: (state) => {
      state.railcard = null;
      state.paidFor = false;
      state.error = null;
    },
    createPendingRailcard: (state) => {
      state.error = null;
    },
    createPendingRailcardFailure: (state, action) => {
      state.error = action.message;
    },
    resetError: (state) => {
      state.error = null;
    },
    setDiscountedPrice: (state, action) => {
      state.railcard.totalPrice = action.payload.discountedPrice;
    }
  },
});

export const selectPendingRailcard = (state) => state.pendingRailcard;

export default pendingRailcardSlice.reducer;
