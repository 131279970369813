import React from 'react';
import './Maintenance.scss';


export default function Maintenance() {

  return <div className='maintenance'>
      <h1>Maintenance</h1>
      <p>Sorry, this website is currently down for maintenance. We will be back shortly.</p>
    </div>
}
