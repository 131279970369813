

import React, { useEffect, useState } from 'react';
import {filter, identity } from 'ramda';
import { Formik, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ReactComponent as ArrowRight } from '../../../../images/arrow-right.svg';
import { useNavigate } from "react-router-dom";
import { APP_PAGES, DOCUMENT_LENGTH } from '../../../../constants';
import documentValidationUtils from "../../../../utils/documentValidationUtils";
import BasketState from "../../../../interfaces/BasketState";
import { selectBasket } from '../../../../features/basket/basketSlice';
import DocumentState from "../../../../interfaces/DocumentState";
import { selectDocuments } from '../../../../features/documents/documentsSlice';
import Railcard from "../../../railcards/railcard/Railcard";
import './ProofOfAge.scss';

import PassportIcon from '../../../../images/passport-card.png';
import LicenseIcon from '../../../../images/driving-card.png';
import IdIcon from '../../../../images/id-card.png';

import { DOCUMENT_TYPES } from '../../../../constants';


const ProofOfAge = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { customerInfo, selectedRailcard, chosenPlan, isRenewing } = useSelector<BasketState, BasketState>(selectBasket);
  let { documentType: renewDocumentType, documentNumber: renewDocumentNumber } = useSelector<DocumentState, DocumentState>(selectDocuments);
  const [ documentType, setDocumentType ] = useState<string | null>(null);

  if (!isRenewing) {
    renewDocumentType = null;
    renewDocumentNumber = null;
  }

  const goNext = () => {
    navigate(APP_PAGES.RAILCARD_PHOTO);
  }

  const goBack = () => {
    navigate(APP_PAGES.USER_INFO);
  }

  const onBackClick = () => {
    if (documentType) {
      return setDocumentType(null);
    }
    goBack();
  }

  const documentTypes = {
    passport: {
      img: PassportIcon,
      text: 'Please provide the first 20 numbers of the long passport number at the bottom of your passport',
      label: 'The first 20 characters of your long passport number *',
      field: DOCUMENT_TYPES.PASSPORT
    },
    license: {
      img: LicenseIcon,
      text: 'Please provide the first 16 characters of your UK driving licence number',
      label: 'Driving license number *',
      field: DOCUMENT_TYPES.UK_DRIVERS_LICENSE
    },
    id: {
      img: IdIcon,
      text: 'Please provide your 18 digit Identity card number:',
      label: 'Identity card number *',
      field: DOCUMENT_TYPES.EEA_IDENTITY_CARD
    }
  }

  type Nullable<T> = T | null;

  interface IValidationErrors {
    passport?: Nullable<string>,
    license?: Nullable<string>,
    id?: Nullable<string>
  }

  useEffect(() => {
    if (!customerInfo.firstName || !selectedRailcard) {
      navigate(APP_PAGES.HOMEPAGE);
    }
  }, [navigate, customerInfo, selectedRailcard]);

  const handleDocumentType = (chosenDocumentType, setFieldValue) => () => {
    if (isRenewing && renewDocumentType === chosenDocumentType) {
      setFieldValue(chosenDocumentType, renewDocumentNumber);
    }
    
    setDocumentType(chosenDocumentType);
  }

  return (
    <Formik
      initialValues={{
        passport: '',
        license: '',
        id: ''
      }}
      validate={(values: IValidationErrors) => {
        const errors: IValidationErrors = {};

        if (documentType === DOCUMENT_TYPES.PASSPORT) {
          errors.passport = documentValidationUtils.validatePassport(values.passport, customerInfo.dateOfBirth);
        }

        if (documentType === DOCUMENT_TYPES.UK_DRIVERS_LICENSE) {
          errors.license = documentValidationUtils.validateLicenseNumber(values.license, customerInfo);
        }

        if (documentType === DOCUMENT_TYPES.EEA_IDENTITY_CARD) {
          errors.id = documentValidationUtils.validateId(values.id, customerInfo.dateOfBirth);
        }
        
        const errs = Object.fromEntries(Object.entries(errors));
        // remove empty properties or equal to null
        return filter(identity, errs);
      }}

      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {

          const data = {
            documentType,
            documentNumber: values[documentType] 
          };

          dispatch({ type: 'documents/setDocuments', payload: { data }});

          setSubmitting(false);
        }, 400);
        goNext();
        
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        submitForm,
      }) => {

        const renderDocumentSelector = (documentType) => {
          if (!documentType) {
            return (
              <>
                <p>
                  We will need you to confirm your age for this railcard, please
                  provide one of the following:
                </p>
                <div className="proof-of-age">
                  <button
                    id="select-proof-of-age-passport"
                    className={`proof-of-age__select-button ${renewDocumentType === DOCUMENT_TYPES.PASSPORT ? 'proof-of-age__select-button--renewed' : ''}`}
                    onClick={handleDocumentType(DOCUMENT_TYPES.PASSPORT, setFieldValue)}
                  >
                    <span className="proof-of-age__select-button__name">
                      <span>Passport number</span>
                      <span className="proof-of-age__select-button__name__number">{renewDocumentType === DOCUMENT_TYPES.PASSPORT ? renewDocumentNumber : ''}</span>
                    </span> <ArrowRight />
                  </button>

                  <button
                    id="select-proof-of-age-drivers-license"
                    className={`proof-of-age__select-button ${renewDocumentType === DOCUMENT_TYPES.UK_DRIVERS_LICENSE ? 'proof-of-age__select-button--renewed' : ''}`}
                    onClick={handleDocumentType(DOCUMENT_TYPES.UK_DRIVERS_LICENSE, setFieldValue)}
                  >
                    <span className="proof-of-age__select-button__name">
                      <span>UK Drivers license number</span>
                      <span className="proof-of-age__select-button__name__number">{renewDocumentType === DOCUMENT_TYPES.UK_DRIVERS_LICENSE ? renewDocumentNumber : ''}</span>
                    </span> <ArrowRight />
                  </button>

                  <button
                    id="select-proof-of-age-eea-identity-card"
                    className={`proof-of-age__select-button ${renewDocumentType === DOCUMENT_TYPES.EEA_IDENTITY_CARD ? 'proof-of-age__select-button--renewed' : ''}`}
                    onClick={handleDocumentType(DOCUMENT_TYPES.EEA_IDENTITY_CARD, setFieldValue)}
                  >
                    <span className="proof-of-age__select-button__name">
                      <span>EEA National identity card number</span>{" "}
                      <span className="proof-of-age__select-button__name__number">{renewDocumentType === DOCUMENT_TYPES.EEA_IDENTITY_CARD ? renewDocumentNumber : ''}</span>
                    </span> <ArrowRight />
                  </button>
                </div>
              </>
            );
          }
      
          const { img, text, label, field } = documentTypes[documentType] || {};
          const maxLength = DOCUMENT_LENGTH[documentType];

          let documentImageAltText = '';

          if (documentType === 'passport') {
            documentImageAltText = 'Image of a mocked Passport document'
          } else if (documentType === 'license') {
            documentImageAltText = 'Image of a mocked UK Drivers License document'
          } else {
            documentImageAltText = 'Image of a mocked EEA National identity card document'
          }
      
          return <div className='document proof-of-age-form'>
            <p>{text}</p>
            <img src={img} className="proof-of-age-form__example-document-image" alt={documentImageAltText} />

            <Form.Group className="mb-3 document__field">
              <Form.Label>{label}</Form.Label>
              <Form.Control 
                name={field}
                type={field}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[field]}
                maxLength={maxLength}
              />
              <ErrorMessage name={field} render={msg => <div className="form-text">{msg}</div>} />
            </Form.Group>
          </div>
        }

        // Cypress helper
        const chooseDocumentType = (e) => {
          e.preventDefault();
        }

        return (
          <div className='user-info'>
            <Row className='user-info__content'>
              <Col>
                <Form onSubmit={chooseDocumentType} className='user-info__form'>
                  <h1>Proof of age</h1>
                  {renderDocumentSelector(documentType)}
                </Form>
              </Col>
              <Col className="d-none d-md-block" md='auto'>
                {selectedRailcard && <Railcard railcard={selectedRailcard} chosenPlan={chosenPlan}/>}
              </Col>
            </Row>
            <footer className='user-info__footer'>
              <Button variant="outline-primary" onClick={onBackClick}>Back</Button>
              {documentType && <Button variant="primary" onClick={submitForm}>Next</Button>}
            </footer>
          </div> 
        )
      }}
    </Formik>
  );
};

export default ProofOfAge;
