import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customerInfo: {
    title: '',
    firstName: '',
    lastName: '',
    dateOfBirth: {
      day: '',
      month: '',
      year: ''
    },
    secondHolderTitle: '',
    secondHolderFirstName: '',
    secondHolderLastName: '', 
  },
  selectedRailcard: null,
  chosenPlan: null,
  isRenewing: false,
  renewedRailcardId: null,
  renewedRailcard: {
    primaryPhotoUUID: null,
    secondaryPhotoUUID: null,
    validUntil: null,
  }
};


export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    selectRailcard: (state, action) => {
      const { railcard = null, chosenPlan = null } = action.payload || {};
      
      state.chosenPlan = chosenPlan;
      state.selectedRailcard = railcard;
      state.isRenewing = false;
      state.renewedRailcardId = null;
      state.renewedRailcard = initialState.renewedRailcard;
    },
    renewRailcard: (state, action) => {
      const { customerInfo = null, selectedRailcard = null, chosenPlan = null, renewedRailcardId, primaryPhotoUUID, secondaryPhotoUUID, validUntil } = action.payload || {};

      state.chosenPlan = chosenPlan;
      state.customerInfo = customerInfo;
      state.selectedRailcard = selectedRailcard;
      state.isRenewing = true;
      state.renewedRailcardId = renewedRailcardId;
      state.renewedRailcard = { primaryPhotoUUID: primaryPhotoUUID, secondaryPhotoUUID: secondaryPhotoUUID, validUntil };
    },
    updateCustomerInfo: (state, action) => {
      const {
        customerInfo
      } = action.payload;

      customerInfo.dateOfBirth = {
        day: customerInfo.dateOfBirth.day.padStart(2, '0'),
        month: customerInfo.dateOfBirth.month.padStart(2, '0'),
        year: customerInfo.dateOfBirth.year
      };

      state.customerInfo = { ...state.customerInfo, ...customerInfo };
    },
    removeCustomerInfo: (state) => {
      state.customerInfo = initialState.customerInfo;
    },
    removeSecondaryCustomerInfo: (state) => {
      state.customerInfo.secondHolderTitle = initialState.customerInfo.secondHolderTitle;
      state.customerInfo.secondHolderFirstName = initialState.customerInfo.secondHolderFirstName;
      state.customerInfo.secondHolderLastName = initialState.customerInfo.secondHolderLastName;
    },
    resetBasket: (state) => {
      state.customerInfo = initialState.customerInfo;
      state.selectedRailcard = null;
      state.chosenPlan = null;
      state.isRenewing = false;
      state.renewedRailcardId = null;
      state.renewedRailcard = initialState.renewedRailcard;
    }
  },
});

export const { updateCustomerInfo, removeCustomerInfo, renewRailcard, selectRailcard, resetBasket } = basketSlice.actions

export const selectBasket = (state) => state.basket;
export const selectBasketRailcard = (state) => state.basket.selectedRailcard;

export default basketSlice.reducer;
