import { defaultTo, divide, flip, pipe } from "ramda";

const priceUtils = {
  formatPrice: pipe(
    defaultTo(0),
    flip(divide)(100),
    (x) => x.toFixed(2),
    (x) => `£${x}`
  ),
  formatPriceToPounds: pipe(defaultTo(0), flip(divide)(100), (x) =>
    x.toFixed(2)
  ),
};

export default priceUtils;
