import { useDropzone } from "react-dropzone";

import { MAX_FILE_UPLOAD_SIZE } from "../../../../constants";

export const useFewDropzone = ({
  file,
  holder,
  setFile,
  setUploadErrors,
  uploadErrors,
}) => {
  return useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    onDropAccepted: (acceptedFiles = []) => {
      setUploadErrors({ ...uploadErrors, [holder]: null });

      if (!acceptedFiles[0]) {
        return;
      }

      const image = new Image();

      image.src = URL.createObjectURL(acceptedFiles[0]);

      image.onload = function () {
        setFile({
          ...file,
          [holder]: Object.assign(acceptedFiles[0], {
            preview: image.src,
          }),
        });

        if (image.width > image.height) {
          setUploadErrors({
            ...uploadErrors,
            [holder]: {
              message:
                "Sorry, we can’t use this image, please upload a portrait image",
            },
          });
        }
      };

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;

          // @ts-ignore - TECH DEBT to resolve later
          setFile({
            ...file,
            [holder]: Object.assign(acceptedFiles[0], {
              binaryStr: binaryStr,
            }),
          });
        };
        reader.readAsArrayBuffer(file);
      });
    },
    onDropRejected: (reason) => {
      console.log(reason);
      setUploadErrors({ ...uploadErrors, [holder]: reason[0]?.errors[0] });
    },
    multiple: false,
    maxFiles: 1,
    maxSize: MAX_FILE_UPLOAD_SIZE,
  });
};
