import * as React from "react";
import { useLocation } from "react-router-dom";

import './ProgressBar.scss';
import { APP_PAGES } from '../../constants'

export default function ProgressBar() {
    const { pathname } = useLocation();
    const shownForPathnames = [APP_PAGES.USER_INFO, APP_PAGES.RAILCARD_PHOTO, APP_PAGES.PAYMENT, APP_PAGES.PROOF_OF_AGE];

    if (!shownForPathnames.includes(pathname)) {
        return;
    }

    const indicatorCN = 'custom-progress-bar__indicator';
    const currentIndicatorCN = `${indicatorCN} ${indicatorCN}_current`;
    const proofOfAgeCN = [APP_PAGES.USER_INFO, APP_PAGES.PROOF_OF_AGE].includes(pathname) ? currentIndicatorCN : indicatorCN;
    const railcardCN = pathname === APP_PAGES.RAILCARD_PHOTO ? currentIndicatorCN : indicatorCN;
    const paymentCN = pathname === APP_PAGES.PAYMENT ? currentIndicatorCN : indicatorCN;

    return (
        <div className='custom-progress-bar'>
            <div className={proofOfAgeCN}>
                <div />
                <label>About you</label>
            </div>
            <hr />
            <div className={railcardCN}>
                <div />
                <label>Railcard photo</label>
            </div>
            <hr />
            <div className={paymentCN}>
                <div />
                <label>Payment</label>
            </div>
        </div>
    )
}
