import { curry } from 'ramda';
import moment from 'moment'
import { RAILCARD_CODES, RAILCARD_AGE_ERRORS } from '../constants' ;

const splitDate = (date) => {
  return {
    day: parseInt(date?.split("-")[2]).toString().padStart(2, '0'),
    month: parseInt(date?.split("-")[1]).toString().padStart(2, '0'),
    year: parseInt(date?.split("-")[0]).toString().padStart(2, '0'),
  };
};

const validateAgeAndRailcardCombination = curry(
	(selectedRailcard, chosenPlan, field, dob) => {
		const { day, month, year } = dob;
		const { path, createError } = field;
		const isFullDate = year && month && day;
	
		if (!isFullDate) {
			return false;
		}
	
		const selectedDate = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
	
		if (!selectedDate.isValid()) {
			return false;
		}
	
		const userAge = moment().diff(selectedDate, 'years');

		let { minAge, maxAge, maxAge3Year, railcardCode } = selectedRailcard;

		minAge ??= userAge;
		maxAge ??= userAge;

		let isEligible = false;

		let message = RAILCARD_AGE_ERRORS.AGE_INCOMPATIBLE;

		if (railcardCode === RAILCARD_CODES['16-25'] && chosenPlan === 3) {
			const correctAgeRange = userAge >= minAge && userAge <= maxAge;
			const correctAgeForPlan = userAge >= minAge && userAge <= maxAge3Year

			isEligible = correctAgeRange && correctAgeForPlan;

			if (correctAgeRange && !correctAgeForPlan) {
				message = RAILCARD_AGE_ERRORS.YNG_3_YEAR_MAX_AGE;
			}
		} else {
			isEligible = userAge >= minAge && userAge <= maxAge;
		}
	
		if (!isEligible) {
			return createError({ path, message });
		}
	
		return true;
	}
);

const ageValidationUtils = {
	splitDate,
	validateAgeAndRailcardCombination,
};

export default ageValidationUtils;
