import apiUtils from "../../utils/apiUtils";


const discountService = {
  applyDiscount: async function ({ config, discountCode }) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';

    const path = `/digital-railcards/railcards/discount`;
    const url = isProd ? `https://api.${baseURL}${path}` : `https://api-${brand}.${baseURL}${path}`;

    const data = { channel: 'web', discount: discountCode };
    
    const response = await apiUtils.put({ apiAccessToken, data, returnResponse: true, url });
    // const response = [];

    if (response.id) { // is an updated railcard
      return ({
        railcard: response,
        success: true
      });
    } else {
      return ({
        success: false,
        error: response.error
      })
    }
  }
};

export default discountService;
