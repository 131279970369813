import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: null,
  success: false,
};

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    register: (state) => {
      state.error = null;
    },
    registerSuccess: (state) => {
      state.error = null;
      state.success = true;
    },
    registerFailure: (state, action) => {
      state.error = action.payload;
      state.success = false;
    },
    resetForm: (state) => {
      state.error = null;
      state.success = false;
    },
  },
});

export const { setErrors } = registerSlice.actions;

export const selectRegister = (state) => state.register;

export default registerSlice.reducer;
