import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  addressError: null,
  amount: 0,
  clientToken: null,
  deviceData: null,
  initAuthFlow: null,
  closeAuthFlow: null,
  teardown: null,
  paymentMethod: null,
  details: null,
  nonce: null,
  ready: false,
  processing: false,
};

export const paypalSlice = createSlice({
  name: 'paypal',
  initialState,
  reducers: {
    getClientTokenSuccess: (state, action) => {
      const {
        clientToken,
      } = action.payload;

      state.clientToken = clientToken;
    },

    paypalReady: (state, action) => {
      const {
        initAuthFlow,
        closeAuthFlow,
        deviceData,
      } = action.payload;

      state.initAuthFlow = initAuthFlow;
      state.closeAuthFlow = closeAuthFlow;
      state.deviceData = deviceData;
      state.ready = true;
    },
    checkout: (state) => {
      state.processing = false;
    },
    checkoutProcessing: (state) => {
      state.processing = true;
    },
    checkoutSuccess: (state) => {
      state.processing = false;
    },
    checkoutFailure: (state) => {
      state.processing = false;
    },
    paymentSuccess: (state, action) => {
      const {
        details,
        nonce,
      } = action.payload;

      state.details = details;
      state.nonce = nonce;
    },
    reset: (state) => {
      state.addressError = null;
      state.amount = 0;
      state.clientToken = null;
      state.deviceData = null;
      state.initAuthFlow = null;
      state.closeAuthFlow = null;
      state.teardown = null;
      state.paymentMethod = null;
      state.details = null;
      state.nonce = null;
      state.ready = false;
      state.processing = false;
    }
  },
});

export const selectPaypal = (state) => state.paypal;
export const selectClientToken = (state) => state.paypal.clientToken;
export const selectIsProcessing = (state) => state.paypal.processing;

export default paypalSlice.reducer;
