import React, { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import Button from "react-bootstrap/esm/Button";
import Modal from 'react-bootstrap/Modal';
import DOMPurify from 'dompurify'
import moment from 'moment';
import { replace } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { APP_PAGES, TRACKING_EVENTS, TRACKING_STEPS } from '../../../constants';
import priceUtils from '../../../utils/priceUtils';
import { trackEvent, getRailcardEventPayload } from '../../../utils/googleTagManager';
import ConfigState from "../../../interfaces/ConfigState"
import { selectConfig } from "../../../features/config/configSlice";
import { ReactComponent as InfoIcon } from '../../../images/info.svg';
import { useTranslation } from 'react-i18next';

import './BasketSummary.scss';


const getRailcardHeading = ({ railcardName, railcardExpirationLength }) => {
  const duration = replace('Y',' year', railcardExpirationLength);
  const suffix = parseInt(railcardExpirationLength) === 1 ? '' : 's';
  return <div><strong>{railcardName}</strong><br /> for <strong>{duration}{suffix}</strong></div>;
};

export default function BasketSummary({ railcard }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { brand, brand: lng } = useSelector<ConfigState, ConfigState>(selectConfig);

  const [showInfoModal, setShowInfoModal] = useState(false);

  const clearBasket = () => {
    dispatch({ type: 'basket/resetBasket'});
    dispatch({ type: 'documents/resetDocuments'});
    dispatch({ type: 'paypal/reset'});
    dispatch({ type: 'pendingRailcard/reset'});

    const payload = getRailcardEventPayload({ railcard, chosenPlan: railcard.railcardExpirationLength });

    trackEvent({
      brand,
      eventName: TRACKING_EVENTS.REMOVED_FROM_BASKET,
      payload,
      step: TRACKING_STEPS.BASKET_EMPTY,
    });

    navigate(APP_PAGES.HOMEPAGE);
  };

  const handleOpenInfoModal = () => {
    setShowInfoModal(true);
  };

  const handleCloseInfoModal = () => {
    setShowInfoModal(false);
  };

  const { 
    created,
    originalPrice,
    primaryFirstName,
    primaryLastName,
    validUntil 
  } = railcard;

  const styles = {
    fill: '#000',
    color: '#fff',
    cursor: 'pointer',
  }
  
  return <div className="basket-summary-container">
    <aside className="basket-summary">
      <div className="railcard-info-icon">
        <Button className="basket-summary-container__more-info-button" onClick={handleOpenInfoModal} variant="hollow">
          <InfoIcon style={styles}/>
        </Button>
      </div>
      <h1 className="basket-summary__heading">{getRailcardHeading(railcard)}</h1>
      <h2 className="basket-summary__subheading">Digital Railcard</h2>

      <ul className="railcard-validity">
        <li className="railcard-validity__item"><strong className="railcard-validity__item__label">Valid from </strong>{moment(created).format("ddd DD MMM YYYY")}</li>
        <li className="railcard-validity__item"><strong className="railcard-validity__item__label">Valid until </strong>{moment(validUntil).format("ddd DD MMM YYYY")}</li>
      </ul>

      <p>Your Digital Railcard can only be stored in the {t('brand', {lng})} app.</p>

      <hr />

      <div className="name-and-price">
        <div><Icon.Person className="mobile-login-buttons__icon" size={22} color="#000" /> {`${primaryFirstName} ${primaryLastName}`}</div>
        <div><strong>{priceUtils.formatPrice(originalPrice)}</strong></div>
      </div>
    </aside>

    <div className="basket-summary__clear-basket">
      <Button variant="link" onClick={clearBasket}>Remove from basket</Button>
    </div>

    <Modal className="railcard-info-modal" show={showInfoModal} onHide={handleCloseInfoModal}>
      <Modal.Header closeButton>
        <Modal.Title>{railcard.railcardName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(railcard.info)}}></div>
      </Modal.Body>
    </Modal>
  </div>
}
