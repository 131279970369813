import * as React from "react";


function Loader({ inline, unwrapped, message, children }) {
    const loader = (
        <div className="loading">
            <div className="otrl circle-spinner" />
            { message && <p className="loading-message">{ message }</p> }
            { children }
        </div>
    );
    return unwrapped || inline ? loader : <div className="left-col left-col--loading">{ loader }</div>;
}

export default Loader;
