import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import ConfigState from "../../interfaces/ConfigState";
import { selectConfig } from '../../features/config/configSlice';
import { ACCESSIBILITY_URLS, BRAND_URLS, HELP_URLS, FAQ_URLS, GENERAL_TERMS_URLS } from '../../constants'


export default function Footer() {
  const { brand: lng, payPalEnabled } = useSelector<ConfigState, ConfigState>(selectConfig);
  const { t } = useTranslation();  
  
  const brandURL = BRAND_URLS[lng];

  return (
    <footer className="site-footer">
      <Row>
        <Col>
          <a className="back-to-toc-site" rel="noopener" href={brandURL}>{t('brand', {lng})}</a>
          <ul className="payment-methods-list">
            {payPalEnabled && (
              <li className="payment-methods-list__item payment-methods-list__item--paypal">
              <span className="sr-only">PayPal</span>
            </li>
            )}
            <li className="payment-methods-list__item payment-methods-list__item--visa">
              <span className="sr-only">Visa</span>
              </li>
              <li className="payment-methods-list__item payment-methods-list__item--mastercard">
              <span className="sr-only">MasterCard</span>
              </li>
              <li className="payment-methods-list__item payment-methods-list__item--maestro">
              <span className="sr-only">Maestro</span>
              </li>
              <li className="payment-methods-list__item payment-methods-list__item--amex">
              <span className="sr-only">American Express</span>
            </li>
            <li className="payment-methods-list__item payment-methods-list__item--safekey">
              <span className="sr-only">Safe Key</span>
            </li>
          </ul>
        </Col>
        <Col>
          <ul className="clearfix site-footer__links d-flex justify-content-end">
            <li>
              <a href={GENERAL_TERMS_URLS[lng]}>Legal</a>
            </li>
            <li>
              <a href={ACCESSIBILITY_URLS[lng]}>Site accessibility</a>
            </li>
            <li>
            <a href={FAQ_URLS[lng]}>FAQ</a>
            </li>
            <li>
              <a href={HELP_URLS[lng]}>Need help?</a>
            </li>
          </ul>
        </Col>
      </Row>
    </footer>
  );
}
