import { call, put, select, takeLatest } from 'redux-saga/effects'
import discountService from '../services/discount';
import { selectConfig } from '../features/config/configSlice';


export function* applyDiscount({ payload: { discountCode  } }) {
  try {
    const config = yield select(selectConfig);
  
    const result = yield call(discountService.applyDiscount, { config, discountCode });

    console.log('apply discount result', result)

    if (!result.success) {
      throw new Error('Discount code not valid');
    }
  
    yield put({type: 'discount/discountCodeSuccess' });
    yield put({type: 'pendingRailcard/setDiscountedPrice', payload: {
      discountedPrice: result.railcard.totalPrice
    }});
  } catch (e) {
    console.log('error applying discount', e);
    yield put({type: 'discount/discountCodeFailure', payload: 'Error applying discount code' });
  }
}

function* discountSaga() {
  yield takeLatest('discount/applyDiscount', applyDiscount);
}

export default discountSaga;
