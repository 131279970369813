
const dateOfBirthToYYYYDDMM = (dob) => {
	const { day, month, year } = dob;

	if (!day || !month || !year) {
		return null;
	}
	
	const formatted = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

	return formatted;
}

const ageFormatUtils = {
	dateOfBirthToYYYYDDMM,
};

export default ageFormatUtils;
