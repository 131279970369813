import { call, delay, put, select, race, take, takeLatest } from 'redux-saga/effects';
import { replace } from 'ramda';
import pay360Service from '../services/pay360';
import { selectBasket } from '../features/basket/basketSlice';
import { selectPendingRailcard } from '../features/pendingRailcard/pendingRailcardSlice';
import { selectUser } from '../features/user/userSlice';
import { selectUserAccessToken } from '../features/user/userSlice';
import { selectPay360 } from '../features/pay360/pay360Slice';
import { APP_PAGES, Pay360TransactionStates, RAILCARD_STATUSES, TRACKING_EVENTS, TRACKING_STEPS } from '../constants';
import { getRailcard } from '../sagas/railcards';
import { selectConfig } from '../features/config/configSlice';
import { trackEvent, getRailcardEventPayload } from '../utils/googleTagManager';


const CANCEL_STATUS_POLLING = "CancelStatusPolling";
const POLLING_DELAY = 2500;


function* createSession () {
  const config = yield select(selectConfig);

  const user = yield select(selectUser);
  const pendingRailcard = yield select(selectPendingRailcard);
  const token = yield select(selectUserAccessToken);

  const amount = pendingRailcard.railcard.totalPrice || pendingRailcard.railcard.originalPrice;

  const data = {
    railcardId: pendingRailcard?.railcard?.id,
    amount,
    address: {
      line1: '',
      line2: '',
      line3: '',
      town: '',
      postCode: '',
      country: '',
    },
  };

  try {
    const session = yield call(pay360Service.createSession, data, user, token, config);
    yield put({type: 'pay360/createSessionSuccess', payload: {
      session,
    } });
  } catch (e) {
    yield put({type: 'pay360/createSessionFailure' });
    console.log('pay360/createSessionFailure', e);
  }
}

// Poll the order and wait for the railcard to become 'Active'.
export function* orderStatusPollingWorker({
  navigate,
  railcardId,
  token
}) {
  while (true) {
    try {
      const config = yield select(selectConfig);
      const railcard = yield call(getRailcard, railcardId, token);
      const pendingRailcard = yield select(selectPendingRailcard);
      const basket = yield select(selectBasket);

      // Order complete, stop polling the order and send user to Order Summary screen
      if (railcard?.state === RAILCARD_STATUSES.ACTIVE) {
        const {
          selectedRailcard: basketRailcard,
        } = basket;

        const payload = getRailcardEventPayload({ 
          railcard: basketRailcard, 
          chosenPlan: parseInt(replace('Y', '', pendingRailcard.railcard.railcardExpirationLength)) 
        });
                
        trackEvent({
          brand: config.brand,
          eventName: TRACKING_EVENTS.PAID,
          payload,
          railcardId: pendingRailcard.railcard.id,
          step: Object.assign(TRACKING_STEPS.PAYMENT_SCREEN, { stepName: 'pay360' }),
        });

        yield put({type: 'pay360/paymentSuccess' });

        yield put({type: 'pendingRailcard/setPaid', payload: {
          paidWith: 'pay360'
        } });

        yield put({ type: CANCEL_STATUS_POLLING });

        navigate(APP_PAGES.ORDER_SUMMARY);
      } else {
        yield delay(POLLING_DELAY);
      }
    } catch (error) {
      console.error(error);
      yield put({type: 'pay360/paymentProcessingError' });
      yield put({ type: CANCEL_STATUS_POLLING });
    }
  }
}

export function* orderStatusWatchWorker({
  navigate,
  railcardId,
  token
}) {
  yield race({
    task: call(orderStatusPollingWorker, {
      navigate,
      railcardId,
      token
    }),
    cancel: take(CANCEL_STATUS_POLLING)
  })
}

function* getSession({ payload: { navigate } }) {
  yield delay(3000); // min 3 second processing time so page doesn't flicker
  try {
    const config = yield select(selectConfig);
    let token = yield select(selectUserAccessToken);
    const { session: { sessionId } } = yield select(selectPay360);
    const pendingRailcard = yield select(selectPendingRailcard);
    const railcardId = pendingRailcard?.railcard?.id;
    const session = yield call(pay360Service.getSession, sessionId, config);

    const { hostedSessionStatus, status } = session;

    const { sessionState, transactionState } = hostedSessionStatus;

    if (sessionState === Pay360TransactionStates.TERMINATED && transactionState.transactionState !== 'NOT_SUBMITTED') {
      yield put({type: 'pay360/paymentProcessingFinished', payload: {
        sessionState,
        transactionState: transactionState.transactionState,
      }});
    }

    if (sessionState === Pay360TransactionStates.TERMINATED && transactionState.transactionState === 'NOT_SUBMITTED') {
      yield put({type: 'pay360/reset' });
    }

    if (
      sessionState === Pay360TransactionStates.TERMINATED &&
      transactionState.transactionState === Pay360TransactionStates.SUCCESS
    ) {
      /*
        Transaction successful

        Poll/watch the railcard order status, wait for it to update
      */
      yield call(orderStatusWatchWorker, {
        navigate,
        railcardId,
        token
      });
    }
    return status;
  } catch (e) {
    // setProcessingFailed
    console.log('error getting pay360 session status', e);
  }
}

function* pay360Saga() {
  yield takeLatest('pay360/createSession', createSession);
  yield takeLatest('pay360/getSession', getSession);
}

export default pay360Saga;
