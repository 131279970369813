import { call, put, select, takeLatest } from 'redux-saga/effects'
import customerService from '../services/customer';
import { selectConfig } from '../features/config/configSlice';


export function* resetPassword({ values }) {
  try {
    const config = yield select(selectConfig);

    const { email } = values;

    yield call(customerService.resetPassword, { email, config });

    yield put({type: 'passwordReset/resetPasswordSuccess'});
  } catch (e) {
    yield put({type: 'passwordReset/resetPasswordFailure', payload: 'password reset error' });
  }
}

function* passwordResetSaga() {
  yield takeLatest('passwordReset/resetPassword', resetPassword);
}

export default passwordResetSaga;
