import React from 'react';
import Col from 'react-bootstrap//Col';
import Pay360Session from './Pay360Session';
import PaymentProcessing from './PaymentProcessing';


function PaymentCardHosted({ paymentProcessing, paymentProcessingFailed, redirectUrl, sessionState, transactionState }) {
    return (
        (
            <div
                className="layout-scrollable--payment-with-terms payment-wrapper"
                id="otrl-payment"
            >
                <div className="payment-container">
                    <div>
                        <section>
                            <Col xs={12} className="payment__content full-height">
                                <div className="payment__header-container">
                                    <h3 className="payment__header sr-only">
                                        Pay by card
                                    </h3>
                                </div>

                                {paymentProcessing ? <PaymentProcessing /> : <Pay360Session
                                    paymentProcessing={paymentProcessing}
                                    paymentProcessingFailed={paymentProcessingFailed}
                                    src={redirectUrl}
                                    sessionState={sessionState}
                                    transactionState={transactionState}
                                />}
                            </Col>
                        </section>
                    </div>
                </div>
            </div>
        )
    );
}

export default PaymentCardHosted;
