import * as React from "react";
import { useDispatch } from 'react-redux';
import  { prop } from 'ramda';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import * as Yup from 'yup';
import { selectPasswordReset } from '../../passwordResetSlice';
import IPasswordResetState from '../../../../interfaces/PasswordResetState';
import '../../../../styles/login-and-register.scss';


interface PasswordResetFormValues {
  email: string;
}

const PasswordResetSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});


const PasswordResetForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();

  const initialValues: PasswordResetFormValues = { email: '' };

  const { errors: passwordResetErrors, success } = useSelector<IPasswordResetState, IPasswordResetState>(selectPasswordReset);

  React.useEffect(() => {
    dispatch({ type: 'passwordReset/resetForm' });
  }, [dispatch]);

  return (<div className="password-reset-form">
    <Formik
      initialValues={initialValues}

      validationSchema={PasswordResetSchema}

      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
        }, 400);
        handleSubmit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        const isDisabled = isSubmitting || !values.email || !!errors.email;

        return (
          <Form onSubmit={handleSubmit}>
            {!success && (<React.Fragment>
              <p>Please enter your email address and we'll send you instructions for resetting your password.</p>

              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email *</Form.Label>
                <Form.Control 
                  name="email"
                  type="email"
                  placeholder="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <Form.Text className="text-muted">
                  {errors.email && touched.email && errors.email}
                </Form.Text>
              </Form.Group>

              <Button type="submit" disabled={isDisabled} className="password-reset-modal__save" variant="primary">
                  Continue
              </Button>
            </React.Fragment>)}

            {success && (<Alert className="error-well" variant="success">
              <p>You have been sent instructions on how to reset your password.</p>
            </Alert>)}

            {passwordResetErrors.length > 0 && (<Alert className="error-well" variant="danger">
            <p>{prop(0, passwordResetErrors).message}</p>
            </Alert>)}
          </Form>
        )
      }}
    </Formik>
  </div>);
};

export default PasswordResetForm;
