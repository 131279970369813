import { CURRENCY_CODE, DATA_LAYER_NAME, GOOGLE_GTMIDS, PRODUCT_CATEGORY, PRODUCT_VARIANT } from "../constants";
import priceUtils from "./priceUtils";


export const trackEvent = ({
  brand,
  eventName,
  payload,
  railcardId,
  step,
}) => {
  const dataLayer = window[DATA_LAYER_NAME];

  if (dataLayer) {
    const {
      id,
      name,
      price,
    } = payload;

    const dataLayerObject = {
      event: `OTRL:${eventName}`,
      ...(step.stepNumber !== 0) && {ecommerce: {
        checkout: {
          id: railcardId, // DRC order ID (won't always be populated)
          actionField: {
            step: step.stepNumber, 
            option: step.stepName,
          }
          // {step: 1, option: "order started"} user is on the "about you" section of the purchase flow
          // {step: 2, option: "<order_id>"} user is on the photocard upload section of the purchase flow
          // {step: 3, option: "<payment option name>"} user is on the payments page of the purchase flow
        },
        currencyCode: CURRENCY_CODE,
        add: {
          products: [
            {
              id, // DRC 3 letter name + "-" + years length (1 or 3) e.g. YNG-1
              name, // name of DRC and length in plain English
              price, // price in pounds
              quantity: 1,
              variant: PRODUCT_VARIANT,
              brand,
              category: PRODUCT_CATEGORY,
              dimension6: "false",
              dimension7: "false",
              dimension8: "false",
              dimension9: false,
              dimension10: false,
              dimension11: false,
              dimension12: false,
              dimension13: false,
              dimension14: "false",
              dimension15: "false",
              dimension16: false,
              dimension17: "false",
              dimension18: "false",
              dimension19: "none"
            }
          ]
        }
      }},
    };

    dataLayer.push(dataLayerObject);
  }
};

export const initGTM = (brand) => {
  function init(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    let f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "//www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  }
  
  window.dataLayer = [
    {
      originalLocation:
        document.location.protocol +
        "//" +
        document.location.hostname +
        document.location.pathname +
        document.location.search,
    },
  ];

  init(window, document, "script", DATA_LAYER_NAME, GOOGLE_GTMIDS[brand]);
};

export const getRailcardEventPayload = ({ railcard, chosenPlan }) => {
    const { railcardCode, railcardName, oneYearPrice, originalPrice, threeYearPrice } = railcard;
    const pencePrice = (chosenPlan === 1 ? oneYearPrice : threeYearPrice) || originalPrice;
    const price = priceUtils.formatPriceToPounds(pencePrice);
    const name = `${railcardName} ${chosenPlan} ${chosenPlan === 1 ? 'year' : 'years'}`;
  
    return {
      id: `${railcardCode}-${chosenPlan}`,
      name,
      price
    }
}
