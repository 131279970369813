import { createSlice } from '@reduxjs/toolkit';
import { Pay360SessionState } from '../../constants';


const initialState = {
  paymentState: null,
  paymentProcessing: false,
  paymentProcessingFailed: false,
  session: null,
  sessionState: null,
  transactionState: null,
};

export const pay360Slice = createSlice({
  name: 'pay360',
  initialState,
  reducers: {
    createSessionSuccess: (state, action) => {
      const {
        session,
      } = action.payload;

      state.session = session;
      state.paymentProcessing = false;
      state.sessionState = Pay360SessionState.READY;
      state.transactionState = null;
    },
    paymentProcessing: (state) => {
      state.paymentProcessing = true;
    },
    paymentSuccess: (state) => {
      state.paymentState = Pay360SessionState.SUCCESS;
      state.paymentProcessing = false;
    },
    reset: (state) => {
      state.session = null;
      state.paymentState = null;
      state.paymentProcessing = false;
    },
    paymentProcessingFinished: (state, action) => {
      const {
        sessionState,
        transactionState
      } = action.payload;

      state.paymentProcessing = false;
      state.sessionState = sessionState;
      state.transactionState = transactionState;
    },
    paymentProcessingError: (state) => {
      state.paymentProcessing = false;
      state.paymentProcessingFailed = true;
      state.sessionState = null;
      state.transactionState = null;
    },
  },
});

export const selectPay360 = (state) => state.pay360;

export default pay360Slice.reducer;
