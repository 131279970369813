import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: null,
  success: false,
};

export const discountSlice = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    discountCodeSuccess: (state) => {
      state.success = true;
    },
    reset: (state) => {
      state.error = null;
      state.success = false;
    }
  },
});

export const selectDiscount = (state) => state.discount;

export default discountSlice.reducer;
