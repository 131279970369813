import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  documentType: null,
  documentNumber: null,
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocuments: (state, action) => {
      const {
        documentType,
        documentNumber,
      } = action.payload.data;
      
      state.documentType = documentType;
      state.documentNumber = documentNumber;
    },
    resetDocuments: (state) => {
      state.documentType = null;
      state.documentNumber = null;
    },
  },
});

export const { setDocuments, resetDocuments } = documentsSlice.actions

export const selectDocuments = (state) => state.documents;

export default documentsSlice.reducer;
