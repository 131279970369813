import * as React from "react";
import DownloadLinks from '../../../../components/downloadLinks/DownloadLinks';
import './Download.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import ConfigState from "../../../../interfaces/ConfigState";
import { selectConfig } from "../../../../features/config/configSlice";

export default function Download() {
    const { brand: lng } = useSelector<ConfigState, ConfigState>(selectConfig);
    const { t } = useTranslation();

    return <section className='download-links'>
        <h1 data-testid="homepage-heading">Digital Railcards</h1>
        <h2 className="download-links__subheading">Digital Railcards save you a <span aria-label="one third">1/3</span> on the price of train tickets</h2>
        <p>Railcards have always been a great way to save money when buying your train ticket, and now with the introduction of our new Digital Railcards you can save money with the convenience of a Digital Railcard on your phone.</p>
        <p>Your Digital Railcard will be stored in your {t('brand', {lng})} app, so your tickets and Railcard are all in the same place, and you can start using it straight away. You can buy Digital Railcards from the {t('brand', {lng})} website and app.</p>
        <p>You can plan your journey and buy tickets for travel across our network and to anywhere in the UK. Plus when you buy direct from us there are no booking fees!</p>
        <p>If you don&apos;t already have the {t('brand', {lng})} app, download it now.</p>
        <DownloadLinks />
    </section>
}
