import { API_DOCUMENT_TYPES  } from '../constants';


const mapFormatToAPI = (document) => {
	return API_DOCUMENT_TYPES[document];
}

const documentFormatUtils = {
	mapFormatToAPI,
};

export default documentFormatUtils;
