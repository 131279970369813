import * as React from "react";
import { useCallback, useMemo } from 'react'
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import gnlogo from '../../images/gn-logo.png';
import gxlogo from '../../images/gx-logo.png';
import selogo from '../../images/se-logo.png';
import snlogo from '../../images/sn-logo.png';
import tblogo from '../../images/tb-logo.png';
import tllogo from '../../images/tl-logo.png';
import GlobalModalsState from '../../interfaces/GlobalModalsState';
import LoginForm from "../../features/login/components/login-form/Login";
import RegisterForm from "../../features/register/components/register-form/Register";
import PasswordResetForm from "../../features/passwordReset/components/password-reset-form/PasswordResetForm";
import { selectConfig } from '../../features/config/configSlice';
import { selectGlobalModals } from '../../features/globalModals/globalModalsSlice';
import ConfigState from "../../interfaces/ConfigState";
import { useTranslation } from 'react-i18next';
import { BOOKING_ENGINE_URLS } from '../../constants';


const getAccountLink = (config) => {
  const { baseURL, brand, environment } = config;

  let link = null;
  
  if (environment === 'dev' || environment === 'sid' || environment === 'stage') {
    link = `https://${brand}.${baseURL}/search`
  } else {
    link =  BOOKING_ENGINE_URLS[brand];
  }

  return link;
};

export default function Header({ user }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const appConfig = useSelector<ConfigState, ConfigState>(selectConfig);

  const { maintenanceMode, brand, brand: lng } = appConfig;

  const {
    errorModalOpen: showErrorModal,
    errorModalMessage,
    errorModalTitle,
    loginModalOpen: showLoginModal,
    registerModalOpen: showRegisterModal,
    passwordResetModalOpen: showPasswordResetModal,
  } = useSelector<GlobalModalsState, GlobalModalsState>(selectGlobalModals);

  const logo = useMemo(() => {
    switch (brand) {
      case 'greatnorthern': return gnlogo;
      case 'gatwick': return gxlogo;
      case 'southern': return snlogo;
      case 'ticketyboo': return tblogo;
      case 'thameslink': return tllogo;
      default: return selogo;
    }
  }, [brand])

  const handleShowLoginModal = () => {
    dispatch({ type: 'globalModals/openLoginModal' });
  };

  const handleCloseLoginModal = useCallback(() => {
    dispatch({ type: 'globalModals/closeLoginModal' });
  }, [dispatch])

  const handleShowRegisterModal = () => {
    dispatch({ type: 'globalModals/openRegisterModal' });
  };

  const handleCloseRegisterModal = () => {
    dispatch({ type: 'globalModals/closeRegisterModal' });
  };

  const handleShowPasswordResetModal = () => {
    dispatch({ type: 'globalModals/openPasswordResetModal' });
  };

  const handleClosePasswordResetModal = () => {
    dispatch({ type: 'globalModals/closePasswordResetModal' });
  };

  const handleLoginSubmit = (values) => {
    dispatch({ type: 'login/userLogin', values });
  };

  const handleRegisterSubmit = (values) => {
    dispatch({ type: 'register/createUser', values });
  };

  const handleResetPasswordSubmit = (values) => {
    dispatch({ type: 'passwordReset/resetPassword', values });
  };

  const logout = () => {
    dispatch({ type: 'login/userLogout'});
  };

  const handleCloseErrorModal = () => {
    dispatch({ type: 'globalModals/closeErrorModal' });
  };
  
  React.useEffect(() => {
    if (user?.loggedIn) {
      handleCloseLoginModal();
    }
  }, [handleCloseLoginModal, user]);
  
  return (
    <header className="app-header-bar">
        <div className="app-header-bar__content">
          <Row>
            <Col>
              <a href="/"><img className="app-header-bar__logo" src={logo} alt={t('brand', {lng})} /></a>
            </Col>
            {!maintenanceMode && (<React.Fragment>
              {user?.loggedIn ? <Col className="app-header-bar__user-info">
                <Dropdown>
                  <Dropdown.Toggle variant="link" id="dropdown-basic">
                    Welcome, {user.firstNames}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href={getAccountLink(appConfig)}>My account</Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Log out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                
              </Col> : <Col>
                <div className="app-header-bar__login-register-buttons desktop-login-buttons">
                  <Button onClick={handleShowLoginModal}>Login</Button>
                  <Button onClick={handleShowRegisterModal} variant="outline-primary">Register</Button>
                </div>
                <div className="mobile-login-buttons">
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <Icon.Person className="mobile-login-buttons__icon" size={20} color="#262078" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleShowLoginModal}>Log in</Dropdown.Item>
                      <Dropdown.Item onClick={handleShowRegisterModal}>Register</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>}
            </React.Fragment>)}
          </Row>
        </div>

        <Modal className="login-modal" show={showLoginModal} onHide={handleCloseLoginModal}>
          <Modal.Header closeButton>
            <Modal.Title>Log in</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LoginForm 
              handleSubmit={handleLoginSubmit}
              handleShowRegisterModal={handleShowRegisterModal}
              handleShowPasswordResetModal={handleShowPasswordResetModal}
            />
          </Modal.Body>
        </Modal>

        <Modal className="register-modal" show={showRegisterModal} onHide={handleCloseRegisterModal}>
          <Modal.Header closeButton>
            <Modal.Title>{user?.loggedIn ? `Hello, ${user.firstNames}!` : 'Create an account'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RegisterForm handleShowLoginModal={handleShowLoginModal} handleSubmit={handleRegisterSubmit} />
          </Modal.Body>
        </Modal>

        <Modal className="password-reset-modal" show={showPasswordResetModal} onHide={handleClosePasswordResetModal}>
          <Modal.Header closeButton>
            <Modal.Title>Forgotten password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PasswordResetForm handleSubmit={handleResetPasswordSubmit} />
          </Modal.Body>
        </Modal>

        <Modal size="lg" className="error-modal" show={showErrorModal} onHide={handleCloseErrorModal}>
          <Modal.Header closeButton>
            <Modal.Title>{errorModalTitle || 'Error'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert className="error-well" variant="danger">
              <p>{errorModalMessage}</p>
            </Alert>
          </Modal.Body>
        </Modal>
      </header>
  );
}
