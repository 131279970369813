import { call, put, select, takeLatest } from 'redux-saga/effects'
import { replace } from 'ramda';
import customerService from '../services/customer';
import { selectConfig } from '../features/config/configSlice';


export function* register({ values }) {
  let errors = null;

  try {
    const config = yield select(selectConfig);

    const { email, firstNames, password, surname, title, products, promotions, travelUpdates, isOver16 } = values;
  
    const result = yield call(customerService.register, {
      email, firstNames, password, surname, title, products, promotions, travelUpdates, isOver16, config
    });

    if (result.errors || !result.accessToken) {
      errors = result.errors;
      throw new Error('Failure to register user');
    }
  
    const { accessToken, id, refreshToken } = result;

    const formattedToken = replace('/customers/auth/', '')(accessToken);

    yield put({type: 'register/registerSuccess'});
    yield put({type: 'user/setUser', payload: { email, firstNames, id, surname, title }});
    yield put({type: 'user/setTokens', payload: {
      accessToken: formattedToken,
      refreshToken
    }});
  } catch (e) {
    yield put({type: 'register/registerFailure', payload: errors[0].message });
  }
}

function* registerSaga() {
  yield takeLatest('register/createUser', register);
}

export default registerSaga;
