import * as React from "react";
import { useDispatch } from 'react-redux';
import  { prop } from 'ramda';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import * as Yup from 'yup';
import { selectLogin } from '../../loginSlice';
import ILoginState from '../../../../interfaces/LoginState';
import '../../../../styles/login-and-register.scss';


interface LoginFormValues {
  email: string;
  password: string;
}

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});

const LoginForm = ({ handleSubmit, handleShowRegisterModal, handleShowPasswordResetModal }) => {
  const dispatch = useDispatch();

  const initialValues: LoginFormValues = { email: '', password: '' };

  const { errors: loginErrors } = useSelector<ILoginState, ILoginState>(selectLogin);
  
  React.useEffect(() => {
    dispatch({ type: 'login/resetForm' });
  }, [dispatch]);

  return (<div className="login-form">
    <Formik
      initialValues={initialValues}

      validationSchema={SignupSchema}

      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
        }, 400);
        handleSubmit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        const isDisabled = isSubmitting || !values.email || !values.password || !!errors.email || !!errors.password;
        
        return (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email *</Form.Label>
              <Form.Control 
                name="email"
                type="email"
                placeholder="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <Form.Text className="text-muted">
                {errors.email && touched.email && errors.email}
              </Form.Text>
            </Form.Group>
  
            <Form.Group className="mb-3" controlId="id">
              <Form.Label>Password *</Form.Label>
              <Form.Control 
                name="password"
                type="password"
                placeholder="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              <Form.Text className="text-muted">
                {errors.password && touched.password && errors.password}
              </Form.Text>
            </Form.Group>
  
            <Button type="submit" disabled={isDisabled} className="login-modal__save" variant="primary">
                Log in
            </Button>

            {loginErrors.length > 0 && (<Alert className="error-well" variant="danger">
              <p>{prop(0, loginErrors).message}</p>
            </Alert>)}

            <footer className="login-modal__actions mt-3">
              <Row>
                <Col>
                  <p>Don't have an account? <Button onClick={handleShowRegisterModal} className="login-modal__actions__button login-modal__actions__button--register" variant="link">Register</Button></p>
                </Col>
                <Col>
                  <Button onClick={handleShowPasswordResetModal} className="login-modal__actions__button login-modal__actions__button--forgotten-password" variant="link">Forgotten your password?</Button>
                </Col>
              </Row>
            </footer>
          </Form>
        )
      }}
    </Formik>
  </div>);
};

export default LoginForm;
