import apiUtils from "../../utils/apiUtils";
import { PAY_360_SKIN_IDS } from '../../constants'; 


const pay360Service = {
  createSession: async function (data, user, token, config) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';

    const path = '/payments/railcards/pay360/hosted';
    const url = isProd ? `https://api.${baseURL}${path}` : `https://api-${brand}.${baseURL}${path}`;
    
    const cardHolderName = `${user.firstNames} ${user.surname}`;
    const email = user.email || '';
    const phone = user.telephoneNumber || '';

    const returnUrl = `${window.location.origin}/pay360-session.html`;

    const skin = PAY_360_SKIN_IDS[brand][environment];
    const {
      railcardId,
      amount,
      address,
    } = data;

    const params = {
      locale: 'en_GB',
      railcardId,
      customerId: user.id,
      amount,
      cardHolderName,
      email,
      phone,
      address: {
          line1: (address && address.line1) || '',
          line2: (address && address.line2) || '',
          line3: (address && address.line3) || '',
          town: (address && address.town) || '',
          postCode: (address && address.postCode) || '',
          country: (address && address.country) || '',
      },
      customFields: [],
      returnUrl,
      skin,
      cardNicknameField: 'none'
    };

    const session = await apiUtils.post({
      apiAccessToken, 
      url, 
      data: params, 
      token
    });
    return session;
  },

  getSession: async function (sessionId, config) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';
    
    const path = `/payments/railcards/pay360/hosted/session/${sessionId}`;
    const url = isProd ? `https://api.${baseURL}${path}` : `https://api-${brand}.${baseURL}${path}`;

    return apiUtils.get({
      apiAccessToken,
      url
    });
  },
};

export default pay360Service;
