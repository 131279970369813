import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
.use(initReactI18next)
  .init({
    debug: true,
    fallbackNS: 'brand',
    resources: {
      'greatnorthern': {
        translation: {
            brand: 'Great Northern'
        }
      },
      'gatwick': {
        translation: {
            brand: 'Gatwick Express'
        }
      },
      'southeastern': {
        translation: {
            brand: 'Southeastern'
        }
      },
      'southern': {
        translation: {
            brand: 'Southern'
        }
      },
      'thameslink': {
        translation: {
            brand: 'Thameslink'
        }
      },
      'ticketyboo': {
        translation: {
            brand: 'TicketyBoo'
        }
      },
    }
  });

export default i18n;
