const userInterfaceUtils = {

	deepLinkAndSmoothScrollToPageElement: function (elementId, delay = 250) {
		if (window.location.href.includes(elementId)) {
			window.setTimeout(function () {
				const el = document.getElementById(elementId);
				el?.scrollIntoView({block: "start", behavior: "smooth"});
			}, delay) // delay to allow for page layout to be ready
		}
	}

};

export default userInterfaceUtils;
