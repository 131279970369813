import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { COOKIE_NAME } from "../../constants";
import RailcardSelector from "./components/railcardSelector/RailcardSelector";
import RailcardsFilter from "./components/railcardsFilter/RailcardsFilter";
import DiscountCode from "./components/discountCode/DiscountCode";
import FAQ from "./components/faq/FAQ";
import Download from "./components/download/Download";
import cookieUtils from "../../utils/cookieUtils";
import { selectRailcards } from "../../features/railcards/railcardsSlice";
import { selectUser } from "../user/userSlice";
import UserState from "../../interfaces/UserState";
import RailcardsState from "../../interfaces/RailcardsState";
import "./Homepage.scss";

export default function Homepage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { renewId } = useParams();

  const enableDiscounts = false;

  const { railcards } = useSelector<RailcardsState, RailcardsState>(
    selectRailcards
  );
  const { accessTokenExpires } = useSelector<UserState, UserState>(selectUser);

  useEffect(() => {
    dispatch({ type: "pendingRailcard/reset" });
    dispatch({ type: "pay360/reset" });

    /*
      automatically log the user in if they have a valid cookie already
      (from a WebTIS login)
    */
    const customerTokenCookie = cookieUtils.getPropFromCookie(
      cookieUtils.parseCookie(document.cookie),
      COOKIE_NAME
    );

    if (customerTokenCookie) {
      const { customer } = customerTokenCookie;
      const { id, firstNames, surname, email } = customer;

      const accessToken = customer?.accessToken?.accessToken;
      const refreshToken = customer?.accessToken?.refreshToken;

      dispatch({
        type: "user/setUser",
        payload: {
          id,
          firstNames,
          surname,
          email,
        },
      });

      dispatch({
        type: "user/setTokens",
        payload: {
          accessToken,
          refreshToken,
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    const tokenExpired = moment(accessTokenExpires).isBefore();

    if (tokenExpired) {
      dispatch({ type: "login/userLogout" });

      dispatch({
        type: "globalModals/openErrorModal",
        payload: {
          message:
            "Sorry, your session has expired and we have logged you out automatically.",
          title: "Session expired",
        },
      });
    }
  }, [accessTokenExpires]);

  useEffect(() => {
    if (renewId && railcards) {
      dispatch({
        type: "railcards/renewRailcard",
        payload: { navigate, renewedRailcardId: renewId, railcards },
      });
    }
  }, [dispatch, navigate, railcards, renewId]);

  return (
    <div className="homepage">
      <Download />
      <RailcardsFilter />
      {enableDiscounts && <DiscountCode />}
      <RailcardSelector />
      <FAQ />
      <div id="paypal-container"></div>
    </div>
  );
}
