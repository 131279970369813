import moment from 'moment';
import { filter, identity, toUpper } from 'ramda';
import * as React from "react";
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'ramda';
import Alert from 'react-bootstrap/Alert';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../../../../components/loader/Loader";
import { selectConfig } from '../../../../features/config/configSlice';
import ConfigState from "../../../../interfaces/ConfigState";
import RailcardsState from '../../../../interfaces/RailcardsState';
import Railcard from '../../../railcards/railcard/Railcard';
import { selectRailcards } from '../../../../features/railcards/railcardsSlice';
import urlUtils from '../../../../utils/urlUtils';
import userInterfaceUtils from '../../../../utils/userInterfaceUtils';
import railcardUtils from '../../../../utils/railcardUtils';
import { APP_PAGES, RAILCARD_CODES, TRACKING_EVENTS, TRACKING_STEPS } from '../../../../constants';
import './RailcardSelector.scss';

import { trackEvent, getRailcardEventPayload } from '../../../../utils/googleTagManager';


export default function RailcardSelector() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { brand } = useSelector<ConfigState, ConfigState>(selectConfig);

  const { 
    error,
    filterOptions,
    isLoading,
    noResultRailcard,
    railcards,
    railcardsTimestamp,
    shownRailcards,
  } = useSelector<RailcardsState, RailcardsState>(selectRailcards);

  const railcardsFilterAge = filterOptions.age;

  // Railcards are cached for 1 calendar day
  const railcardsExpired = moment(railcardsTimestamp).isBefore(moment(), 'day');

  useEffect(() => {
    if (isEmpty(railcards) || railcardsExpired) {
      dispatch({ type: 'railcards/getRailcards' });
    }
  }, [dispatch, railcards, railcardsExpired]);
  
  // Reset the filters when component mounts
  useEffect(() => {
    dispatch({ type: 'railcards/resetFilters' });
  }, [dispatch]);

  // if the URL contains preselected railcard data, attempt to jump to form step with railcard selected
  useEffect(() => {
    const preselectedRailcard = urlUtils.getUrlParameterByName('selectedRailcard');
    const chosenPlan = parseInt(urlUtils.getUrlParameterByName('duration'));

    if (preselectedRailcard) {
      const railcard = railcards.find(railcard =>
        // railcardCode matches query string param like 'NEW'
        (railcard.railcardCode === toUpper(preselectedRailcard)
        // railcardName matches query string param like 'network-railcard'
        || railcardUtils.slugify(railcard.railcardName) === railcardUtils.slugify(preselectedRailcard))
        // railcard has a compatible plan length
        && railcardUtils.hasChosenPlan(railcard, chosenPlan)
      );

      // Pre-select the matching railcard
      if (railcard) {
        dispatch({
          type: 'basket/selectRailcard',
          payload: {
            railcard,
            chosenPlan
          }
        });

        const payload = getRailcardEventPayload({ railcard, chosenPlan });

        trackEvent({
          brand,
          eventName: TRACKING_EVENTS.ADDED_TO_BASKET,
          payload,
          step: TRACKING_STEPS.ORDER_STARTED,
        });

        // Send user to User Info screen with railcard preselected
        navigate(APP_PAGES.USER_INFO);
      }
    }
  }, [dispatch, navigate, railcards]);

  useEffect(() => {
    userInterfaceUtils.deepLinkAndSmoothScrollToPageElement('railcard-options');
  }, []);

  if (isLoading) {
    return <Loader
        message="Loading railcards"
        unwrapped={false}
        inline={false}
        children={[]}
    />
  }

  if (error) {
    return (<Alert className="mb-5" variant='danger'>
      Sorry, an error has occurred loading railcards. Please try again later.
    </Alert>);
  }
  
  const renderRailcards = () => {
    const isNoResult = !shownRailcards.length && noResultRailcard;

    if (isNoResult) {
      return <div>
        <p>Unfortunately, there's no best railcard for you; you can however save 1/3 on rail tickets when travelling with a friend when using the Two Together Railcard.</p>
        <ul className="railcards-wrapper">
          <Railcard railcard={ noResultRailcard } />
        </ul>
      </div>
    }

    /*
      CORE-13599 - On the home page we should display the DRCs in the following order

      16-25 Railcard
      Senior Railcard
      Network Railcard
      26-30 Railcard
      Two Together Railcard
      Family & Friends Railcard
      16-17 Saver
    */

    const sortedRailcards = filter(identity, [
      shownRailcards.find(railcard => railcard.railcardCode === RAILCARD_CODES['16-25']),
      shownRailcards.find(railcard => railcard.railcardCode === RAILCARD_CODES.SENIOR),
      shownRailcards.find(railcard => railcard.railcardCode === RAILCARD_CODES.NETWORK),
      shownRailcards.find(railcard => railcard.railcardCode === RAILCARD_CODES['26-30']),
      shownRailcards.find(railcard => railcard.railcardCode === RAILCARD_CODES.TWO_TOGETHER),
      shownRailcards.find(railcard => railcard.railcardCode === RAILCARD_CODES.FAMILY_AND_FRIENDS),
      shownRailcards.find(railcard => railcard.railcardCode === RAILCARD_CODES['16-17']),
    ])

    return (
      <ul className="railcards-wrapper">
        {
          sortedRailcards.map((railcard) => <Railcard key={railcard.railcardCode} railcard={railcard} railcardsFilterAge={railcardsFilterAge} />)
        } 
      </ul>
    );

  }

  return (
    <section className="railcard-options" id="railcard-options">
      {renderRailcards()}
      {/* <ul>
        <li>
          <p>Please note that the Disabled Persons Railcard and Veterans Railcard are not available to buy with us. We are currently working on the ability to verify eligibility.</p>
        </li>
      </ul> */}
    </section>
  );
} 
