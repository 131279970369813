import * as React from "react";
import Form from 'react-bootstrap/Form';

import './Select.scss'

export default function CustomSelect({ label, onChange, options }) {
    return (
        <Form.Group className='custom-select'>
            <Form.Label>{label}</Form.Label>
            <Form.Select
                onChange={e => {
                    onChange(e.target.value);
                }}
                defaultValue="select"
            >
                <option disabled hidden value="select">Select an option</option>

                {options.map(({ value, name })=> <option key={name} value={value}>{name}</option>)}
            </Form.Select>
        </Form.Group>
    )
}