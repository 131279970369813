
import apiUtils from "../../utils/apiUtils";


const customerService = {
  auth: async function ({ email, password, config }) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';

    const path = '/customers/auth';
    const url = isProd ? `https://api.${baseURL}${path}` : `https://api-${brand}.${baseURL}${path}`;

    const data = {
      username: email,
      password,
      grant_type: 'password',
      session_short: true
    };
    
    const response = await apiUtils.post({
      apiAccessToken, url, data
    });

    const { errors, links, result } = response;

    let user;
    let accessToken;
    let accessTokenExpires;
    let refreshToken;

    if (result) {
      user = links[result.customer];
      accessToken = result.accessToken;
      accessTokenExpires = result.expires;
      refreshToken = result.accessToken;
    }

    return ({
      accessToken,
      accessTokenExpires,
      errors,
      refreshToken,
      user
    });
  },

  logout: async function ({ user, config }) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';

    if (user?.loggedIn) {
      const path = '/customers/auth';
      const url = isProd ? `https://api.${baseURL}${path}/${user.accessToken}` : `https://api-${brand}.${baseURL}${path}/${user.accessToken}`;

      await apiUtils.delete({
        apiAccessToken,
        url
      });
    }
  },

  register: async function ({
    email, firstNames, password, surname, title, products, promotions, travelUpdates, isOver16, config
  }) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';

    const path = '/customers';
    const url = isProd ? `https://api.${baseURL}${path}` : `https://api-${brand}.${baseURL}${path}`;

    const data = {
      title,
      firstNames,
      surname,
      email,
      password,
      referrer: "DIGITAL_RAILCARDS_REGISTER_MODAL",
      preferences:
        {
          emailServiceUpdates: travelUpdates,
          emailPromotions: promotions,
          emailProducts: products,
          isOver16,
          smsServiceUpdates: false, // not used by DRC Portal but needs to be passed
          smsPromotions: false, // not used by DRC Portal but needs to be passed
          smsProducts: false, // not used by DRC Portal but needs to be passed
          autoDelayRepay: false, // not used by DRC Portal but needs to be passed
          autoDelayRepayApp: false, // not used by DRC Portal but needs to be passed
          autoDelayRepayEmail: false, // not used by DRC Portal but needs to be passed
          delayRepayAmazonEmail: null, // not used by DRC Portal but needs to be passed
          delayRepayAmazonCustomerId: null, // not used by DRC Portal but needs to be passed
        }
    };
    
    const response = await apiUtils.post({
      apiAccessToken, url, data
    });

    const { result } = response;

    if (result) {
      const {
        accessToken,
        email,
        id,
        firstNames,
        refreshToken,
        title,
        surname,
      } = result;

      return {
        accessToken,
        email,
        id,
        firstNames,
        refreshToken,
        title,
        surname,
      };
    } else {
      return response;
    }
  },

  resetPassword: async function ({ email, config }) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';

    const path = '/customers/reset-password';
    const url = isProd ? `https://api.${baseURL}${path}` : `https://api-${brand}.${baseURL}${path}`;

    const data = { email };

    try {
      await apiUtils.post({
        apiAccessToken, url, data
      });
    } catch (e) {
      console.log('some error', e);
    }
  },
};

export default customerService;
