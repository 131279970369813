import ageFormatUtils from "../../utils/ageFormatUtils";
import apiUtils from "../../utils/apiUtils";
import documentFormatUtils from "../../utils/documentFormatUtils";


const railcardsService = {
  getRailcards: async function (config) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';

    const path = '/digital-railcards/';
    const url = isProd ? `https://api.${baseURL}${path}` : `https://api-${brand}.${baseURL}${path}`;
    
    const railcards = await apiUtils.get({
      apiAccessToken,
      url
    });

    if (railcards) {
      return ({ railcards });
    }
  },

  createPendingRailcard: async function ({ config, customerId, railcardInfo, userInfo, documentInfo, primaryPhoto, secondaryPhoto, token }) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';

    const path = `/digital-railcards/${customerId}`;
    const url = isProd ? `https://api.${baseURL}${path}` : `https://api-${brand}.${baseURL}${path}`;

    const {
      railcardCode,
      railcardExpirationLength,
    } = railcardInfo;

    const {
      title: primaryTitle,
      firstName: primaryFirstName,
      lastName: primaryLastName,
      dateOfBirth,
      secondHolderTitle: secondaryTitle,
      secondHolderFirstName: secondaryFirstName,
      secondHolderLastName: secondaryLastName,
    } = userInfo;

    const {
      documentType,
      documentNumber
    } = documentInfo;

    let primaryPhotoBlob = null;
    let secondaryPhotoBlob = null;

    if (primaryPhoto?.binaryStr) {
      primaryPhotoBlob = new Blob([primaryPhoto?.binaryStr]);
    }
    
    if (secondaryPhoto?.binaryStr) {
      secondaryPhotoBlob = new Blob([secondaryPhoto?.binaryStr]);
    }

    const data = {
      channel: 'web',
      railcardCode,
      railcardExpirationLength,
      primaryTitle,
      primaryFirstName,
      primaryLastName,
      dateOfBirth: ageFormatUtils.dateOfBirthToYYYYDDMM(dateOfBirth),
      secondaryTitle,
      secondaryFirstName,
      secondaryLastName,
      documentType: documentFormatUtils.mapFormatToAPI(documentType),
      documentNumber,
      primaryPhoto: primaryPhotoBlob,
      secondaryPhoto: secondaryPhotoBlob,
    };
    
    const railcards = await apiUtils.postFormData({
      apiAccessToken,
      url,
      data,
      token
    });

    if (railcards) {
      return ({ railcards });
    }
  },

  renewRailcard: async function ({ config, customerId, railcardInfo, userInfo, documentInfo, primaryPhoto, renewedRailcardId, secondaryPhoto, token }) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';

    const path = `/digital-railcards/${customerId}/railcards/${renewedRailcardId}/renew`;
    const url = isProd ? `https://api.${baseURL}${path}` : `https://api-${brand}.${baseURL}${path}`;

    const {
      railcardCode,
      railcardExpirationLength,
    } = railcardInfo;

    const {
      title: primaryTitle,
      firstName: primaryFirstName,
      lastName: primaryLastName,
      dateOfBirth,
      secondHolderTitle: secondaryTitle,
      secondHolderFirstName: secondaryFirstName,
      secondHolderLastName: secondaryLastName,
    } = userInfo;
    
    const {
      documentType,
      documentNumber
    } = documentInfo;

    let primaryPhotoBlob = null;
    let secondaryPhotoBlob = null;

    if (primaryPhoto?.binaryStr) {
      primaryPhotoBlob = new Blob([primaryPhoto?.binaryStr]);
    }
    
    if (secondaryPhoto?.binaryStr) {
      secondaryPhotoBlob = new Blob([secondaryPhoto?.binaryStr]);
    }

    const data = {
      channel: 'web',
      railcardCode,
      railcardExpirationLength,
      primaryTitle,
      primaryFirstName,
      primaryLastName,
      dateOfBirth: ageFormatUtils.dateOfBirthToYYYYDDMM(dateOfBirth),
      secondaryTitle,
      secondaryFirstName,
      secondaryLastName,
      documentType: documentFormatUtils.mapFormatToAPI(documentType),
      documentNumber,
      primaryPhoto: primaryPhotoBlob,
      secondaryPhoto: secondaryPhotoBlob,
    };

    const railcards = await apiUtils.postFormData({
      apiAccessToken,
      url,
      data,
      token
    });

    if (railcards) {
      return ({ railcards });
    }
  },

  updatePendingRailcardRenewalPreference: async function ({ config, customerId, sendRenewalNotification, railcardId, token }) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';

    const data = { sendRenewalNotification };

    const path = `/digital-railcards/${customerId}/railcards/${railcardId}/renewalNotification`;
    const url = isProd ? `https://api.${baseURL}${path}` : `https://api-${brand}.${baseURL}${path}`;

    await apiUtils.put({ 
      apiAccessToken, 
      data, 
      token, 
      url 
    });
  },

  getRailcard: async function (config, railcardId, token) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';

    const path = `/digital-railcards/railcard/${railcardId}`;
    const url = isProd ? `https://api.${baseURL}${path}` : `https://api-${brand}.${baseURL}${path}`;

    const railcard = await apiUtils.get({
      apiAccessToken, 
      url, 
      token
    });

    return railcard;
  },

  getCustomerRailcards: async function ({ config, customerId, token }) {
    const { apiAccessToken, baseURL, brand, environment } = config;

    const isProd = environment === 'prod';

    const path = `/digital-railcards/${customerId}/railcards`;
    const url = isProd ? `https://api.${baseURL}${path}` : `https://api-${brand}.${baseURL}${path}`;

    await apiUtils.get({ 
      apiAccessToken,
      token, 
      url 
    });
  },
  
  getImage: async function ({ config, imageUrl, token }) {
    const { apiAccessToken, brand } = config;

    return await apiUtils.getImage({
      apiAccessToken,
      brand,
      token,
      imageUrl
    })
  },
};

export default railcardsService;
